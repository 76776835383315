import httpClient from './http.client';

const getProducts = () => {
  return httpClient.get('/payment-initialization/');
};

const getUsersToNotification = (genre, startAge, endAge) => {
  if (genre.length > 0) {
    let url = `/payment-users/`;
    for (let i = 0; i < genre.length; i++) {
      if (i === 0) {
        url += `?genre=${genre[i]}`;
      } else {
        url += `&genre=${genre[i]}`;
      }
    }
    if (startAge && endAge && endAge > startAge) return httpClient.get(`${url}&age_min=${startAge}&age_max=${endAge}`);
    return httpClient.get(`${url}`);
  }

  if (startAge && endAge) {
    return httpClient.get(`/payment-users?age_min=${startAge}&age_max=${endAge}`);
  }
  return httpClient.get('/payment-users');
};

export const productsServices = {
  getProducts,
  getUsersToNotification,
};
