import React, {useEffect, useState} from 'react';
import {AiOutlinePoweroff} from 'react-icons/ai';
import {useNavigate} from 'react-router';
import {usersService} from '../../api/users.service';
import {authService} from '../../api/auth.service';
import {crudService} from '../../api/crud.service';
import translateEntities from '../../providers/entitiesTranslator';
import {entitiesList} from '../../providers/entitiesProvider';
import SideBarLink from './SideBarLink';
import logo from '../../assets/logo.png';
import './sideBar.css';

function BSideBar() {
  const navigation = useNavigate();

  const [isAdmin] = useState(false);
  const [user, setUser] = useState();
  const [percentChange, setPercentChange] = useState();
  const [buttonClicks, setButtonClicks] = useState();

  useEffect(() => {
    usersService.getCurrentUser().then((res) => {
      setUser(res.data);
      crudService.getAll('/partners/').then((response) => {
        response.data.filter((x) => x.user.id === res.data.id && setPercentChange(x.clicks_avg));
        response.data.filter(
          (x) =>
            x.user.id === res.data.id && x.clicks.click_sum && setButtonClicks(x.clicks.click_sum)
        );
      });
    });
  }, []);

  const onLogout = (formData) => {
    authService.logout(formData);
    navigation('/');
  };

  return (
    <div className='text-center bg-sidemenu h-auto bg-no-repeat min-w-[180px]'>
      <img alt='logo' src={logo} />
      <div className='text-white space-y-1'>
        <div className='text-center text-white px-5 pb-2 text-2xl font-bold'>Impressionen</div>
        <div className='text-xl font-bold'>{user ? user.visitors : 0}</div>
        {percentChange && percentChange >= 0 && (
          <div className='text-green-500'>+{percentChange.toFixed(2)}%</div>
        )}
        {percentChange && percentChange < 0 && (
          <div className='text-red-500'>{percentChange.toFixed(2)}%</div>
        )}
        <div className='text-white px-5 pb-2 text-xl font-bold'>Hear Me! Klicks</div>
        <div className='text-xl font-bold'>{buttonClicks ? buttonClicks : 0}</div>
      </div>
      <ul className='list-unstyled components px-3'>
        {!isAdmin ? (
          entitiesList.map((name) => {
            if (name === 'lieder-vorhoren') return null;
            return (
              <div key={name}>
                <SideBarLink key={name} optionName={translateEntities(name)} route={`/${name}`} />
              </div>
            );
          })
        ) : (
          <div>
            <SideBarLink
              key={'lieder-vorhoren'}
              optionName={translateEntities('lieder-vorhoren')}
              route={'/lieder-vorhoren'}
            />
          </div>
        )}
      </ul>
      <div className='px-3 absolute w-full bottom-5'>
        <button
          type='button'
          onClick={onLogout}
          className='text-white py-2 cursor-pointer hover:bg-green-accent
           transition duration-200 ease-in-out rounded-md w-full flex justify-center items-center'
        >
          <AiOutlinePoweroff className='mr-1' />
          Ausloggen
        </button>
      </div>
    </div>
  );
}

export default BSideBar;
