import React, {useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {usersService} from '../../api/users.service';
import {currentTrackIdAtom, tracksListAtom} from '../../atoms/tracks.atom';
import LoadingIndicator from '../../components/indicators/LoadingIndicator';
import ErrorIndicator from '../../components/indicators/ErrorIndicator';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';
import UsersFilter from '../../components/filters/UsersFilter';
import usePlayerVisibility from '../../components/hooks/usePlayerVisibility';
import SearchTable from './SearchTable';

const SearchPage = () => {
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pickedGenres, setPickedGenres] = useState([]);
  const [pickedSex, setPickedSex] = useState();
  const [pickedLanguages, setPickedLanguages] = useState([]);
  const [startAge, setStartAge] = useState(1);
  const [endAge, setEndAge] = useState(99);
  const [filterSended, setFilterSended] = useState(0);

  const setTrackList = useSetRecoilState(tracksListAtom);
  const setCurrentTrackIndex = useSetRecoilState(currentTrackIdAtom);

  usePlayerVisibility();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUsers();
        setError(false);
        setIsLoading(true);

        usersService
          .getFilteredUsers(pickedLanguages, pickedGenres, pickedSex, startAge, endAge)
          .then((res) => {
            setUsers(res.data.filter((x) => x.artist !== null));
          });
      } catch (error) {
        if (!error.response || error.response.status === 404) setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [filterSended]);

  const tracks = (artistId) => {
    let tracks = [];

    usersService
      .getArtistDetails(artistId)
      .then((res) => {
        let albumsLength = res.data.artist?.albums.length;
        for (let i = 0; i < albumsLength; i++) {
          let tracksLenth = res.data.artist?.albums[i].tracks.length;
          for (let j = 0; j < tracksLenth; j++) {
            let track = res.data.artist.albums[i].tracks[j];
            const trackWithAlbumName = {...track, albumName: res.data.artist.albums[i].name};
            tracks.push(trackWithAlbumName);
          }
        }

        setTrackList(tracks);
        setCurrentTrackIndex(tracks[0].id);
      })
      .finally(() => {});
  };

  if (isLoading) return <LoadingIndicator />;
  if (error) return <ErrorIndicator />;

  return (
    <>
      <BNavigateBox title='Suchen' />

      <div className='h-[96.5vh] m-4 space-y-1'>
        <UsersFilter
          setPickedGenres={setPickedGenres}
          pickedGenres={pickedGenres}
          setStartAge={setStartAge}
          setEndAge={setEndAge}
          setPickedLanguages={setPickedLanguages}
          pickedLanguages={pickedLanguages}
          setPickedSex={setPickedSex}
          setButtonClicked={setFilterSended}
          buttonClicked={filterSended}
          setUsers={setUsers}
        />

        {users?.length > 0 ? (
          <SearchTable users={users} tracks={tracks} />
        ) : (
          <div className=''>no results</div> /// TODO
        )}
      </div>
    </>
  );
};

export default SearchPage;
