export const calculateAgeFromDateBirth = (birth_date) => {
    let today = new Date();
    let birthDate = new Date(birth_date);    
    let age_now = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    return age_now;
  };

  export const ageCategoryPicker = (age) => {
    if (age <= 20) return '16-20';
		if (age <= 25) return '20-25';
		if (age <= 35) return '25-35';
		if (age <= 50) return '35-50';
		if (age > 51) return '+50';
    return 'Keine alter';
  }