import React from 'react';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';
import {checkMusicFile} from '../../utils/checkIsAudioFile';

const MessagesElements = ({data, currentUserEmail, endRef}) => {
  const handleUserClick = useUserNavigate();
  const formatDate = (time) => new Date(time).toLocaleTimeString().slice(0, 11);

  return (
    <div className='overflow-y-auto h-full'>
      {data?.length !== 0 &&
        data?.map((item) => {
          if (item.sender.email === currentUserEmail) {
            return (
              <div key={item.id} ref={endRef}>
                <div className='flex'>
                  <div className='w-1/2'></div>
                  <div className='flex justify-end w-1/2'>
                    <div className='bg-green-accent rounded-xl mt-5 py-1.5 px-4 mr-4 border-black-accent text-white break-all'>
                      {checkMusicFile(item.content)}
                      {item.image !== null && (
                        <img alt='' width={200} src={'https://hearme-app.com' + item?.image} />
                      )}
                    </div>
                  </div>
                </div>
                <div className='text-xs text-gray-400 flex justify-end mr-4'>
                  {formatDate(item.timestamp).slice(0, 5)}
                </div>
              </div>
            );
          }
          return (
            <div className='ml-4 w-fit' key={item.id}>
              <div className='text-black-accent bg-gray-300 rounded-xl mt-5 py-1.5 px-4 mr-1.5 border-black-accent break-all flex'>
                {checkMusicFile(item.content, () => handleUserClick(item.sender.id))}
                {item.image !== null && (
                  <img alt='' width={200} src={'https://hearme-app.com' + item?.image} />
                )}
              </div>
              <div className='text-xs text-gray-400'>{formatDate(item.timestamp).slice(0, 5)}</div>
            </div>
          );
        })}
    </div>
  );
};

export default MessagesElements;
