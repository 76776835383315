// TODO unused component
import {atom} from 'recoil';
import {usersService} from '../api/users.service';

export const users = atom({
  key: 'users',
  default: null,
  effects: [
    async () => {
      const users = await usersService.getUsers();
      return users;
    },
  ],
});

export const receiverMailAtom = atom({
  key: 'receiverMail',
  default: null,
});
