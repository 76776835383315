const translateFieldHeader = (field) => {
  switch (field) {
    case 'First Name': return "Vorname";
    case 'File': return "Lied";
    case 'Genre': return "Musikrichtung";
    case 'Artist': return "Künstler";
    case 'Image': return "Bild";
    case 'Language': return "Sprache";
    case 'Rating': return "Bewertung";
    case 'Avg_rating': return "Durchschnittliche Bewertung";
    case 'Duration': return "Dauer";
    case 'Created_at': return "Erstellungsdatum";
    default: return field;
  }
};

export default translateFieldHeader;