import React from 'react';
import Table from 'rc-table';
import {useRecoilState, useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router';
import {usersService} from '../../api/users.service';
import {currentTrackIdAtom, playingAtom} from '../../atoms/tracks.atom';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';
import playIcon from '../../assets/player/play.svg';
import pauseIcon from '../../assets/player/pause.svg';
import musicNoteIcon from '../../assets/music_note_icon.svg';

const SearchTable = ({users, tracks}) => {
  const navigate = useNavigate();
  const handleUserClick = useUserNavigate();
  const currentTrackId = useRecoilValue(currentTrackIdAtom);
  const [playing, setPlaying] = useRecoilState(playingAtom);

  const contactUnlockButtons = (record) => (
    <button
      className={`py-1 px-2 shadow-md rounded-md ${
        record.index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
      }`}
      onClick={() => {
        if (record.user.is_unlocked) {
          handleUserClick(record.user.id);
        } else {
          handleUnlockUser(record.user.id);
        }
      }}
    >
      {record.user.is_unlocked ? 'Entsperrt' : 'Profilfreischaltung'}
    </button>
  );

  const handleUnlockUser = async (userId) => {
    let hasError = false;

    try {
      const request = await usersService.unlockProfile(userId);
      return request;
    } catch (error) {
      hasError = true;
    } finally {
      if (hasError) {
        navigate('/kaufoptionen');
        return;
      }

      navigate('/kontakte', {state: {selectedTab: 'unlocked'}});
      window.location.reload();
    }
  };

  const handlePlayPause = (isPlaying, userId) => {
    setPlaying((prev) => !prev);
    if (isPlaying) tracks(userId);
  };

  const playButton = (record) => {
    const user = record?.user;
    const artist = user?.artist;
    const albums = artist?.albums;

    return (
      <div className='w-16 pl-2 hover:cursor-pointer'>
        {albums?.some((album) => album.tracks.length > 0) && (
          <>
            {playing &&
            artist.albums.some((album) =>
              album.tracks.some((track) => track.id === currentTrackId)
            ) ? (
              <button onClick={() => handlePlayPause(false, user.id)}>
                <img alt='Pause' src={pauseIcon} className='w-10' />
              </button>
            ) : (
              <button onClick={() => handlePlayPause(true, user.id)}>
                <img alt='Play' src={playIcon} className='w-10' />
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  const userButton = (record) => (
    <button
      className='text-mirage hover:cursor-pointer hover:text-green-accent hover:pb-1'
      onClick={() => handleUserClick(record.userId)}
    >
      {record.userId}
    </button>
  );

  const musicIcon = (
    <button className='flex justify-start items-center'>
      <img alt='music_note_icon' src={musicNoteIcon} className='w-14 pl-2' />
    </button>
  );

  const userId = <div className='flex justify-start items-center'>User ID</div>;
  const tableCss = 'rounded-xl bg-green-accent text-left text-lg text-white';
  const tableRowCss = (index) =>
    `h-16 text-left text-mirage bg-white ${index % 2 === 0 && 'bg-gray-100'}`;

  const searchColumns = [
    {
      title: musicIcon,
      dataIndex: 'player',
      key: 'player',
      width: 100,
      render: (record) => playButton(record),
    },
    {
      title: userId,
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (record) => userButton(record),
    },
    {
      title: 'Durchschnittliche Bewertung',
      dataIndex: 'avgRating',
      key: 'avgRating',
      width: 100,
    },
    {
      title: 'Bewertung',
      dataIndex: 'rating',
      key: 'rating',
      width: 100,
    },

    {
      title: 'Albumnummer',
      dataIndex: 'albumsCount',
      key: 'albumsCount',
      width: 100,
    },
    {
      title: 'Registrierdatum',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: 100,
    },
    {
      title: 'Profil freischalten',
      dataIndex: 'unlockProfile',
      key: 'unlockProfile',
      width: 100,
      render: (record) => contactUnlockButtons(record, 'Profilfreischaltung'),
    },
  ];

  return (
    <>
      {users?.length !== 0 && (
        <Table
          rowKey='id'
          scroll={{
            x: false,
            y: users.length > window.innerHeight * 0.8 ? undefined : '57.3vh',
          }}
          className={tableCss}
          rowClassName={(record, i) => tableRowCss(i)}
          columns={searchColumns}
          data={users
            ?.sort((a, b) => a.id - b.id)
            .map((user, index) => {
              const artist = user?.artist;

              return {
                player: {user: user},
                id: {userId: user.id},
                avgRating: artist?.avg_rating.toFixed(1),
                rating: artist?.rating ? artist?.rating : 0,
                albumsCount: artist?.tracks_count ? artist?.tracks_count : 0,
                registrationDate: user?.register_date.slice(0, 10),
                unlockProfile: {user: user, index: index},
              };
            })}
        />
      )}
    </>
  );
};

export default SearchTable;

/// // dodane przez klienta cholera wie co robi
/* <div className='w-60 ml-2'>
  {lock ? (
    <button
      className='shadow-md rounded-md w-[75%] h-10'
      onClick={() => {
        lockProfile(artist.id);
      }}
    >
      lock
    </button>
  ) : artist.is_unlocked ? (
    <p className='font-semibold'>Vertrag abgeschlossen</p>
  ) : (
    <button
      className='shadow-md rounded-md w-[75%] h-10'
      onClick={() => {
        unlockProfile(artist.id);
      }}
    >
      Profilfreischaltung
    </button>
  )}
</div> */
