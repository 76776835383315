import React, { useState } from "react";
import { IoCaretDown } from "react-icons/io5";

const BTableDropdown = ({ fields, onToggleField, visibleFields }) => {
  const [toggler, setToggler] = useState(false);

  return (
    <div
      className="mr-2 shadow-md cursor-pointer relative w-52"
      onMouseEnter={() => setToggler(true)}
      onMouseLeave={() => setToggler(false)}
    >
      <div className="flex items-center justify-between hover:bg-gray-100 py-1 px-2 border-gray-800 border rounded">
        <div className="text-gray-800 font-bold">Columns</div>
        <IoCaretDown
          className={`${
            toggler ? "transform rotate-180" : null
          } text-gray-800 transition-all duration-75`}
        />
      </div>
      {toggler && (
        <div className="absolute flex flex-col bg-white border-gray-800 border-l border-r left-0 w-full transition-all duration-75 shadow-md z-10 top-7 pt-1 rounded-b border-b">
          {fields &&
            fields.map((el) => {
              return (
                <div
                  key={el.Header}
                  className={
                    "px-2 py-1 text-left text-gray-800 border-gray-800 border-t " +
                    (visibleFields.includes(el)
                      ? "bg-white hover:bg-gray-100"
                      : "bg-gray-100 text-opacity-40")
                  }
                  onClick={() => onToggleField(el)}
                >
                  {el.Header}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default BTableDropdown;

