import React from 'react';
import Spinner from '../spinners/Spinner';

const LoadingIndicator = () => {
  return (
    <div className='p-6'>
      <div className='bg-white rounded shadow-md mt-6 w-full'>
        <div className='p-9 flex flex-col items-center justify-center'>
          <div className='font-bold text-lg'>Loading...</div>
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
