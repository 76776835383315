import React, {useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {crudService} from '../api/crud.service';
import BFormWrapper from '../components/forms/FormWrapper';
import BLoadingIndicator from '../components/indicators/LoadingIndicator';

export default function BAddPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const {entities} = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const tryAdd = (formData) => {
    const id = location.state.id;
    formData.albumId = id;

    setIsLoading(true);
    setErrors([]);
    setSuccess(false);

    if (entities !== 'tracks') {
      crudService
        .addForm(`/${entities}/`, formData)
        .then(() => {
          setSuccess(true);
          navigate(-1);
        })
        .catch((err) => {
          setSuccess(false);

          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => setIsLoading(false));
    }

    if (entities === 'tracks') {
      crudService
        .add(`/${entities}/`, formData)
        .then(() => {
          setSuccess(true);
          navigate(-1);
        })
        .catch((err) => {
          setSuccess(false);

          if (err.response) {
            setErrors(err.response.data);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {isLoading && <BLoadingIndicator />}
      {!isLoading && <BFormWrapper responseErrors={errors} success={success} name={entities} onSubmit={tryAdd} />}
    </>
  );
}
