import BForm from '../components/forms/Form';

function FormRecoverPassword({onSubmit}) {
  const fields = [
    {
      fieldName: 'email',
      placeholder: 'e-mail',
    },
  ];

  return <BForm fields={fields} btnText='Senden' submit={onSubmit} scroll='' />;
}

export default FormRecoverPassword;
