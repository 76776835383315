export const translateRegisterError = (error) => {
  if (error.response.data.email && error.response.data.email[0] === 'user with this email address already exists.') {
    return ('Benutzer mit dieser E-Mail-Adresse existiert bereits.');
  } 
  if (error.response.data.email && error.response.data.email[0] === 'Enter a valid email address.'){
    return ('Geben sie eine gültige E-Mail-Adresse an.');
  } 
  if (error.response.data.genres && error.response.data.genres[0] === 'This field is required.'){
    return ('Das Feld Genres ist erforderlich.')
  } 
  if (error.response.data.languages && error.response.data.languages[0] === 'This field is required.'){
    return ('Das Feld Sprachen ist erforderlich.')
  } 
  if (error.response.data.sex && error.response.data.sex[0] === 'This field is required.'){
    return ('Das Feld Anrede ist erforderlich.')
  } 
  return ('Fehler!');
};
