import React from 'react';
import BoxContact from '../../components/boxes/BoxContact';

const MessageListItem = ({data}) => (
  <>
    {data && data?.length !== 0 ? (
      data?.map((item) => item && <BoxContact key={item.id} item={item} />)
    ) : (
      <div>Du hast keine Nachrichten</div>
    )}
  </>
);

export default MessageListItem;
