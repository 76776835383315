import React from 'react';

export const BNavigateBox = ({title, backgroundColor}) => {
  return (
    <div
      className={`py-3 pl-3 absolute mt-1 ml-10 rounded-md w-56 cursor-pointer ${
        backgroundColor ? backgroundColor + ' text-black-accent' : 'bg-green-accent text-white'
      }`}
    >
      {title}
    </div>
  );
};
