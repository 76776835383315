import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {paymentsService} from '../../api/payments.service';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';

const BPaymentsPage = () => {
  const {checkoutId, paymentId} = useParams();
  const [, setIsLoading] = useState(false);
  const [, setError] = useState(false);

  const redirUrl = window.location.origin + '/payments/' + paymentId + '/results/';

  useEffect(() => {
    setIsLoading(true);
    setError(false);
    paymentsService
      .getPayments()
      .catch((err) => {
        if (!err.response || err.response.status === 404) {
        }
        setError(true);
      })
      .finally((_) => {
        setIsLoading(false);
      });

    const langScript = document.createElement('script');

    //langScript.innerHTML = 'var wpwlOptions = { locale: "de"}';
    langScript.innerHTML =
      'var wpwlOptions = { locale: "de", style: "card", onReady: function(){var aaa = document.getElementsByClassName("wpwl-button-pay"); aaa[0].style.color = "#000"; } }';
    document.body.appendChild(langScript);
    const script = document.createElement('script');
    script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`; /// prod payments
    // script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`; /// test payments TODO
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }; // eslint-disable-next-line
  }, []);

  return (
    <>
      <BNavigateBox title={'Kaufoptionen'} />

      <div className='bg-white  min-h-[75%] max-h-full rounded shadow-md flex flex-col border-gray-400 py-2 px-3 mt-8 m-6'>
        <div className='flex flex-col h-full justify-start items-center mt-5 w-full space-x-3'>
          <form action={redirUrl} className='paymentWidgets' data-brands='PAYPAL EPS'></form>
        </div>
      </div>
    </>
  );
};

export default BPaymentsPage;
