import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {partnerService} from '../../api/partners.service';
import {usersService} from '../../api/users.service';
import {checkedGeneres} from '../../atoms/generes.atom';
import {currentTrackIdAtom, playingAtom, tracksListAtom} from '../../atoms/tracks.atom';
import {selectContactTabAtom} from '../../atoms/contacts.atoms';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';
import ErrorIndicator from '../../components/indicators/ErrorIndicator';
import LoadingIndicator from '../../components/indicators/LoadingIndicator';
import usePlayerVisibility from '../../components/hooks/usePlayerVisibility';
import ContactsTable from './ContactsTable';
import UnlockedTable from './UnlockedTable';
import ContractedTable from './ContractedTable';

import playIcon from '../../assets/player/play.svg';
import pauseIcon from '../../assets/player/pause.svg';

const ContactsPage = () => {
  usePlayerVisibility();
  const location = useLocation();
  const navigate = useNavigate();

  const [contacts, setContacts] = useState([]);
  const [unlocked, setUnlocked] = useState([]);
  const [contracted, setContracted] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const checkedType = useRecoilValue(checkedGeneres);
  const currentTrackId = useRecoilValue(currentTrackIdAtom);
  const setTrackList = useSetRecoilState(tracksListAtom);
  const [playing, setPlaying] = useRecoilState(playingAtom);
  const [selectedTab, setSelectedTab] = useRecoilState(selectContactTabAtom);

  const selectedTabFromState = location.state?.selectedTab;

  useEffect(() => {
    if (selectedTabFromState) setSelectedTab(selectedTabFromState);
  }, [selectedTabFromState, setSelectedTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);

        const contactsRes = await partnerService.getAllPartnerContacts();
        const tempContacts = contactsRes.data;

        const unlockedRes = await partnerService.getUnlocked();
        const tempUnlockedUsers = unlockedRes.data;

        const contractedRes = await partnerService.getContracted();
        const tempContracted = contractedRes.data;

        // bez tego kazda piosenka jaka wyslal artysta bedzie osobnym kontaktem
        const filteredContacts = tempContacts.filter(
          (object, index, self) => self.findIndex((x) => x.user.id === object.user.id) === index
        );

        const lockedContacts = filteredContacts.filter(
          (contact) => !tempUnlockedUsers.some((unlocked) => unlocked.id === contact.user.id)
        );

        setContacts(lockedContacts);
        setUnlocked(tempUnlockedUsers);
        setContracted(tempContracted);
      } catch (error) {
        if (!error.response || error.response.status === 404) setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [checkedType, selectedTab]);

  const tracks = (artistId) => {
    let tracks = [];

    usersService.getArtistDetails(artistId).then((res) => {
      let albumsLength = res.data.artist?.albums.length;
      for (let i = 0; i < albumsLength; i++) {
        let tracksLenth = res.data.artist?.albums[i].tracks.length;
        for (let j = 0; j < tracksLenth; j++) {
          let track = res.data.artist.albums[i].tracks[j];
          const trackWithAlbumName = {...track, albumName: res.data.artist.albums[i].name};
          tracks.push(trackWithAlbumName);
        }
      }
      setTrackList(tracks);
    });
  };

  const handleUnlockUser = async (userId) => {
    let hasError = false;

    try {
      const request = await usersService.unlockProfile(userId);
      return request;
    } catch (error) {
      hasError = true;
    } finally {
      if (hasError) {
        navigate('/kaufoptionen');
        return;
      }

      setSelectedTab('unlocked');
      navigate('/kontakte', {state: {selectedTab: 'unlocked'}});
      window.location.reload();
    }
  };

  const handleContractUser = (artistId) => {
    partnerService.acceptContract(artistId);
    setSelectedTab('contracted');
  };

  const handlePlayPause = (isPlaying, userId) => {
    setPlaying((prev) => !prev);
    if (isPlaying) tracks(userId);
  };

  const playButton = (record) => {
    const user = record?.user;
    const artist = user?.artist;

    return (
      <div className='w-16 pl-2 hover:cursor-pointer'>
        {artist?.tracks?.length > 0 && (
          <>
            {playing && artist.tracks.some((track) => track.id === currentTrackId) ? (
              <button onClick={() => handlePlayPause(false, user.id)}>
                <img alt='Pause' src={pauseIcon} className='w-10' />
              </button>
            ) : (
              <button onClick={() => handlePlayPause(true, user.id)}>
                <img alt='Play' src={playIcon} className='w-10' />
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  const contactUnlockButtons = (record, text) => (
    <button
      className={`py-1 px-2 shadow-md rounded-md ${
        record.index % 2 === 0 ? 'bg-white' : 'bg-gray-100'
      }`}
      onClick={() => {
        if (text === 'Profilfreischaltung') {
          handleUnlockUser(record.userId);
          return;
        }
        handleContractUser(record.userId);
      }}
    >
      {text}
    </button>
  );

  const headers = [
    {id: 'contacts', name: 'Offene Kontakte'},
    {id: 'unlocked', name: 'Entsperrte'},
    {id: 'contracted', name: 'Unter Vertrag'},
  ];

  const noContacts = <div className='p-10 text-2xl text-black-accent'>Du hast keine Kontakte</div>;

  if (isLoading) return <LoadingIndicator />;
  if (error) return <ErrorIndicator />;

  return (
    <>
      <BNavigateBox title='Kontakte' />

      <div className='h-[96.5vh] bg-white rounded shadow-md m-4'>
        <div className='flex justify-end p-3'>
          <div className='flex space-x-3'>
            {headers.map((header) => (
              <div
                key={header.id}
                className={`border-2 px-3 py-1 rounded-md cursor-pointer ${
                  selectedTab === header.id && 'bg-green-accent text-white'
                }`}
                onClick={() => setSelectedTab(header.id)}
              >
                {header.name}
              </div>
            ))}
          </div>
        </div>

        {selectedTab === 'contacts' && (
          <ContactsTable
            contacts={contacts}
            playButton={playButton}
            contactUnlockButtons={contactUnlockButtons}
          />
        )}
        {selectedTab === 'unlocked' && (
          <UnlockedTable unlocked={unlocked} contactUnlockButtons={contactUnlockButtons} />
        )}
        {selectedTab === 'contracted' && (
          <ContractedTable contracted={contracted} playButton={playButton} />
        )}

        {contacts.length === 0 && selectedTab === 'contacts' && noContacts}
        {unlocked.length === 0 && selectedTab === 'unlocked' && noContacts}
        {contracted.length === 0 && selectedTab === 'contracted' && noContacts}
      </div>
    </>
  );
};

export default ContactsPage;
