import React, {useEffect, useState, Fragment} from 'react';
import {useRecoilValue} from 'recoil';
import {usersService} from '../../api/users.service';
import {checkedGeneres} from '../../atoms/generes.atom';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';
import BErrorIndicator from '../../components/indicators/NoDataIndicator';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';

const BStatsPage = () => {
  const handleUserClick = useUserNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [artists, setArtists] = useState();
  const checkedType = useRecoilValue(checkedGeneres);

  let number = 0;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(false);

      const res = await usersService.getUsers();
      let filteredArtists = res.data;

      // Filter checked artists by genre from settings page
      if (checkedType?.length > 0) {
        filteredArtists = Object.values(filteredArtists).filter((artist) =>
          checkedType.includes(artist.genre[0]?.name)
        );
      }

      // Get for artists track's length and average song duration
      const processedArtists = filteredArtists.map((artist) => {
        let tracksLength = 0;
        let totalDuration = 0;

        if (artist.artist && artist.artist.albums) {
          artist.artist.albums.forEach((album) => {
            if (album.tracks) {
              tracksLength += album.tracks.length;
              album.tracks.forEach((track) => {
                totalDuration += parseFloat(track.duration);
              });
            }
          });
        }

        const trackAverageDuration =
          tracksLength > 0 ? (totalDuration / tracksLength).toFixed(2) : 0;

        return {...artist, tracksLength, trackAverageDuration};
      });

      setArtists(processedArtists);
    } catch (err) {
      if (!err.response || err.response.status === 404) console.error(err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [checkedType]);

  if (isLoading) return <BLoadingIndicator />;
  if (error) return <BErrorIndicator />;

  return (
    <>
      <BNavigateBox title='Statistiken' />
      <div className='bg-white min-h-[50%] rounded shadow-md flex flex-col border-gray-400 py-2 px-3 mt-6 m-6'>
        <div className='flex space-x-5'>
          <div className='w-full'>
            <div>
              <div className='flex h-12 items-center justify-start mt-12 bg-green-accent text-lg text-white w-full space-x-2'>
                <div className='w-72 pl-2'>User ID</div>
                <div className='w-72 pl-2 border-l-2'>Anzahl Tracks</div>
                <div className='w-72 pl-2 border-l-2'>Beliebtesten Sprachen</div>
                <div className='w-72 pl-2 border-l-2'>Die Beliebteste Art</div>
                <div className='w-72 pl-2 border-l-2'>Durchschnittliche Streckenlänge</div>
              </div>

              <>
                {artists?.length !== 0 ? (
                  artists &&
                  artists.map((artist) => {
                    artist.artist !== null && number++;
                    return (
                      <Fragment key={artist.id}>
                        {artist.artist !== null && (
                          <div
                            className={
                              number % 2 === 0
                                ? 'flex h-12 items-center bg-white text-lg w-full justify-start'
                                : 'flex h-12 items-center bg-gray-200 text-lg w-full justify-start'
                            }
                          >
                            <div
                              className='w-72 ml-3 hover:cursor-pointer hover:text-green-accent hover:underline hover:pb-1'
                              onClick={() => handleUserClick(artist.id)}
                            >
                              {artist.id}
                            </div>
                            <div className='w-72 ml-2'>{artist.tracksLength}</div>
                            <div className='w-72 ml-2'>
                              {artist.language.length > 0 && artist.language[0].name}
                            </div>
                            <div className='w-72 ml-2'>{artist.genre[0].name}</div>
                            <div className='w-72 ml-2'>{artist.trackAverageDuration}s</div>
                          </div>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <div className='py-5'>Du hast keine Artists</div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BStatsPage;
