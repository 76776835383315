import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {useLocation} from 'react-router';
import {usersService} from '../../api/users.service';
import {checkedGeneres} from '../../atoms/generes.atom';
import {selectRankTabAtom} from '../../atoms/ranks.atoms';
import {currentTrackIdAtom, playingAtom, tracksListAtom} from '../../atoms/tracks.atom';
import BErrorIndicator from '../../components/indicators/NoDataIndicator';
import LoadingIndicator from '../../components/indicators/LoadingIndicator';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';
import TopArtistsTable from './TopArtistsTable';
import TopUnlockedArtistsTable from './TopUnlockedArtistsTable';
import TopTracksTable from './TopTracksListTable';
import TopListenedTracksTable from './TopListenedTracksTable';

import playIcon from '../../assets/player/play.svg';
import pauseIcon from '../../assets/player/pause.svg';

const RanksPage = () => {
  const location = useLocation();

  const [topTracks, setTopTracks] = useState([]);
  const [topArtists, setTopArtists] = useState([]);
  const [topUnlockedUsers, setTopUnlockedUsers] = useState([]);
  const [topListenedTracks, setTopListenedTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const checkedType = useRecoilValue(checkedGeneres);
  const currentTrackId = useRecoilValue(currentTrackIdAtom);
  const setTrackList = useSetRecoilState(tracksListAtom);
  const [playing, setPlaying] = useRecoilState(playingAtom);
  const [selectedTab, setSelectedTab] = useRecoilState(selectRankTabAtom);

  const selectedTabFromState = location.state?.selectedTab;

  const headers = [
    {id: 'topUsers', name: 'Top Users'},
    {id: 'topTracks', name: 'Top Tracks'},
  ];

  useEffect(() => {
    if (selectedTabFromState) setSelectedTab(selectedTabFromState);
  }, [selectedTabFromState, setSelectedTab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const artistsRes = await usersService.getTopArtists();
        const unlockedRes = await usersService.getTopUnlockedArtists();
        const tracksRes = await usersService.getTopTracks();
        const listenedRes = await usersService.getTopListenedTracks();

        if (checkedType?.length > 0) {
          const checkedTypedArtists = Object.values(artistsRes.data).filter((num) =>
            checkedType.includes(num.genres[0]?.name)
          );
          const checkedTypedUnlockedArtists = Object.values(unlockedRes.data).filter((num) =>
            checkedType.includes(num.genres[0]?.name)
          );

          setTopArtists(checkedTypedArtists);
          setTopUnlockedUsers(checkedTypedUnlockedArtists);
        } else if (checkedType?.length === 0) {
          setTopArtists(artistsRes.data);
          setTopUnlockedUsers(unlockedRes.data);
        }

        if (checkedType?.length > 0) {
          const checkedTypedTracks = Object.values(tracksRes.data).filter((num) =>
            checkedType.includes(num.genres[0]?.name)
          );
          const checkedTypedListenedTracks = Object.values(listenedRes.data).filter((num) =>
            checkedType.includes(num.genres[0]?.name)
          );

          setTopTracks(checkedTypedTracks);
          setTopListenedTracks(checkedTypedListenedTracks);
        } else if (checkedType?.length === 0) {
          setTopTracks(tracksRes.data);
          setTopListenedTracks(listenedRes.data);
        }
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [checkedType, selectedTab]);

  const playButton = (record) => {
    const user = record?.user;
    const userId = user?.id;
    const artist = user?.artist;
    const handlePlayPause = (isPlaying) => {
      setPlaying((prev) => !prev);
      if (isPlaying) tracks(userId);
    };

    return (
      <div className='w-16 pl-2 hover:cursor-pointer'>
        {artist?.tracks?.length > 0 && (
          <>
            {playing && artist.tracks.some((track) => track.id === currentTrackId) ? (
              <button onClick={() => handlePlayPause(false)}>
                <img alt='Pause' src={pauseIcon} className='w-10' />
              </button>
            ) : (
              <button onClick={() => handlePlayPause(true)}>
                <img alt='Play' src={playIcon} className='w-10' />
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  const tracks = (artistId) => {
    let tracks = [];

    usersService.getArtistDetails(artistId).then((res) => {
      let albumsLength = res.data.artist?.albums.length;
      for (let i = 0; i < albumsLength; i++) {
        let tracksLenth = res.data.artist?.albums[i].tracks.length;
        for (let j = 0; j < tracksLenth; j++) {
          let track = res.data.artist.albums[i].tracks[j];
          const trackWithAlbumName = {...track, albumName: res.data.artist.albums[i].name};
          tracks.push(trackWithAlbumName);
        }
      }
      setTrackList(tracks);
    });
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <BErrorIndicator />;

  return (
    <>
      <BNavigateBox title='Ranglisten' />
      <div className='h-[96.5vh] bg-white rounded shadow-md m-4'>
        <div className='flex justify-end p-3'>
          <div className='flex space-x-3'>
            {headers.map((header) => (
              <div
                key={header.id}
                className={`border-2 px-3 py-1 rounded-md cursor-pointer ${
                  selectedTab === header.id && 'bg-green-accent text-white'
                }`}
                onClick={() => setSelectedTab(header.id)}
              >
                {header.name}
              </div>
            ))}
          </div>
        </div>

        {selectedTab === 'topUsers' && (
          <div className='flex items-start'>
            <TopArtistsTable artists={topArtists} playButton={playButton} />
            <TopUnlockedArtistsTable artists={topUnlockedUsers} playButton={playButton} />
          </div>
        )}

        {selectedTab === 'topTracks' && (
          <div className='flex items-start'>
            <TopTracksTable tracks={topTracks} playButton={playButton} />
            <TopListenedTracksTable tracks={topListenedTracks} playButton={playButton} />
          </div>
        )}
      </div>
    </>
  );
};

export default RanksPage;
