import {entitiesNames} from './entitiesProvider';

export default function translateEntities(entities) {
  switch (entities) {
    case entitiesNames.KONTAKTE:
      return 'Kontakte';
    case entitiesNames.SONGPREVIEW:
      return 'Lieder vorhoren';
    case entitiesNames.SEARCH:
      return 'Suchen';
    case entitiesNames.CONVERSATIONS:
      return 'Nachrichten';
    case entitiesNames.PROFILEDIT:
      return 'Profil bearbeiten';
    case entitiesNames.NEWS:
      return 'News';
    case entitiesNames.COMPANYDATA:
      return 'Impressum';
    case entitiesNames.RANGLISTEN:
      return 'Ranglisten';
    // case entitiesNames.STATS: // TODO
    //   return 'Statistiken';
    case entitiesNames.SETTINGS:
      return 'Einstellungen';
    case entitiesNames.PAYMENTS:
      return 'Kaufoptionen';
    default:
      break;
  }
}
