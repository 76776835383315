import React, {useEffect, useState} from 'react';
import {songServices} from '../../api/song.service';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';
import BResourcePage from '../resources/ResourcePage';

const BSongPreviewPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [songs, setSongs] = useState();

  useEffect(() => {
    const songsProvider = () => {
      setLoading(true);
      songServices
        .getSongs()
        .then((response) => {
          setSongs(response.data);
        })
        .catch(() => {
          setError('Sie sind kein Administrator!');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    songsProvider();
  }, []);

  return (
    <>
      {loading ? (
        <BLoadingIndicator />
      ) : (
        <>
          {error ? (
            <p className='text-red-500 font-sans font-bold mb-10 text-center'>{error}</p>
          ) : (
            <BResourcePage songs={songs} />
          )}
        </>
      )}
    </>
  );
};

export default BSongPreviewPage;
