import React, {useEffect, useState} from 'react';
import {BNavigateBox} from '../components/navigateBox/NavigateBox';
import {crudService} from '../api/crud.service';
import BLoadingIndicator from '../components/indicators/LoadingIndicator';
import {IonIcon} from '@ionic/react';
import {addOutline, pencilOutline, trashBinOutline, checkmarkOutline, closeOutline} from 'ionicons/icons';
import {partnerService} from '../api/partners.service';

const BNewsPage = () => {
  const [id, setId] = useState();
  const [news, setNews] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isEditingNews, setIsEditingNews] = useState();
  const [editNews, setEditNews] = useState();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setError();
    setIsLoading(true);
    crudService.getAll('/partners').then((response) => {
      crudService
        .getAll('/current-user')
        .then((res) => {
          // eslint-disable-next-line
          response.data.map((x) => {
            if (x.user.id === res.data.id) {
              setId(x.id);
            }
          });
        })
        .catch(() => setError('Fehler beim Laden der Daten! Bitte versuchen Sie es später noch einmal!'));
    });
  }, []);

  useEffect(() => {
    id &&
      crudService
        .getAll(`/partners/${id}/`)
        .then((res) => {
          setNews(res.data.news);
        })
        .catch((err) => setError('Fehler beim Laden der Daten! Bitte versuchen Sie es später noch einmal!'))
        .finally(() => setIsLoading(false));
  }, [id, isEditingNews, isOpenPopup]);

  const handleOpenNews = (newsId) => {
    setIsEditingNews(newsId);
  };

  const handlePostNews = (value) => {
    partnerService.postNews(value).then(() => {
      setIsOpenPopup(false);
    });
  };

  const handleEditNews = (newsId, value) => {
    partnerService.editNews(newsId, value).then(() => {
      setIsEditingNews();
    });
  };

  const handleDeleteNews = (newsId) => {
    partnerService.deleteNews(newsId).then(() => {
      setIsEditingNews(newsId + 100);
    });
  };

  if (isLoading) return <BLoadingIndicator />;

  return (
    <>
      {isOpenPopup && (
        <div className='w-full flex justify-center'>
          <div className='w-full h-full bg-gray-900 opacity-60 absolute z-20'></div>
          <div className='bg-white rounded-xl absolute z-30 top-1/4'>
            <p className='ml-4 text-green-accent font-bold mt-4 mb-2'>News</p>
            <textarea
              className='border border-green-accent w-80 mx-4 rounded-md h-20 p-1'
              onChange={(e) => setEditNews(e.target.value)}
            />
            <div className='w-full flex justify-center'>
              <button
                className='bg-green-accent p-1 mb-4 rounded-lg shadow-lg text-white w-40'
                onClick={() => handlePostNews(editNews)}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      )}
      <BNavigateBox title={'News'} />
      <div className='bg-white  rounded shadow-md flex flex-col min-h-[50%] h-auto border-gray-400 py-2 px-3 mt-10 m-6 pb-20'>
        <div className='flex justify-end'>
          <button
            className='border-2 shadow-lg flex rounded-lg p-2 hover:bg-gray-100 my-5 mr-5'
            onClick={() => setIsOpenPopup(true)}
          >
            <p className='font-semibold'>Neuigkeiten hinzufügen</p>
            <IonIcon icon={addOutline} className='text-xl ml-1 mt-0.5' />
          </button>
        </div>
        {error && <p className='text-red-500 text-center text-lg'>{error}</p>}
        {news &&
          news.toReversed().map((field) => {
            return (
              <div key={field} className='border-2 shadow-md my-2 rounded-md'>
                <div className='flex justify-end text-xl pr-3 pt-3'>
                  <button>
                    <IonIcon icon={pencilOutline} onClick={() => handleOpenNews(field.id)} />
                  </button>
                  <button>
                    <IonIcon
                      icon={trashBinOutline}
                      className='text-red-500 ml-2'
                      onClick={() => handleDeleteNews(field.id)}
                    />
                  </button>
                </div>
                <p className='font-medium text-sm mt-[-20px] ml-3'>{field.created_at.slice(0, 10)}</p>
                {isEditingNews === field.id ? (
                  <div className='flex'>
                    <textarea
                      defaultValue={field.news}
                      className='border rounded-md ml-5 mb-2 w-3/4 p-1'
                      maxLength={256}
                      onChange={(e) => setEditNews(e.target.value)}
                    />
                    <div className='ml-2'>
                      <button className='hover:bg-gray-100'>
                        <IonIcon
                          icon={checkmarkOutline}
                          className='text-xl text-green-accent'
                          onClick={() => handleEditNews(field.id, editNews)}
                        />
                      </button>
                      <br />
                      <button className='hover:bg-gray-100'>
                        <IonIcon
                          icon={closeOutline}
                          className='text-xl text-red-500'
                          onClick={() => setIsEditingNews()}
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <p className='ml-5 mb-2'>{field.news}</p>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default BNewsPage;
