import React from "react";

const BErrorIndicator = ({ errors }) => {
  return (
    <div className="p-6">
      <div className="bg-white rounded shadow-md mt-6 w-full">
        <div className="p-10 font-bold text-lg text-center">Error Occurred</div>
        {errors &&
          errors.map((message) => (
            <div className="p-10 text-red-600 text-center" key={message}>{message}</div>
          ))}
      </div>
    </div>
  );
};

export default BErrorIndicator;
