import React, {useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {genresService} from '../../api/genres.service';
import {paymentsService} from '../../api/payments.service';
import {productsServices} from '../../api/products.service';
import {setPromotionAtom} from '../../atoms/promotions.atom';
import {MdOutlineClear} from 'react-icons/md';

const NotificationsPromotion = ({priceInfo}) => {
  const [users, setUsers] = useState();
  const [pickedUsers, setPickedUsers] = useState([]);
  const [pickedUsersLength, setPickedUsersLength] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [genres, setGenres] = useState();
  const [filterGenre, setFilterGenre] = useState([]);
  const [startAge, setStartAge] = useState();
  const [endAge, setEndAge] = useState();
  const [notificationPopup, setNotificationPopup] = useState(false);

  const setPromotionInfo = useSetRecoilState(setPromotionAtom);

  useEffect(() => {
    productsServices.getUsersToNotification(filterGenre, startAge, endAge).then((res) => {
      setUsers(res.data);
    });

    genresService.getAllGenres().then((res) => {
      setGenres(res.data);
    });
  }, [filterGenre, startAge, endAge]);

  const handleClick = (e) => {
    if (e.target.checked === true) {
      let tempArray = pickedUsers;
      tempArray.push(+e.target.value);
      setPickedUsers(tempArray);
      setPickedUsersLength(pickedUsersLength + 1);
    } else {
      for (let i = 0; i < pickedUsers.length; i++) {
        if (+e.target.value === pickedUsers[i]) {
          let tempArray = pickedUsers;
          tempArray.splice(i, 1);
          setPickedUsers(tempArray);
          setPickedUsersLength(pickedUsersLength - 1);
        }
      }
    }
  };

  const handleClickAll = () => {
    if (disabled) {
      setPickedUsers([]);
      setPickedUsersLength(0);
      setDisabled(false);
    } else {
      let tempArray = [];
      for (let i = 0; i < users.length - 1; i++) {
        tempArray.push(users[i].id);
      }
      setPickedUsers(tempArray);
      setPickedUsersLength(users.length - 1);
      setDisabled(true);
    }
  };

  const handleConfirm = () => {
    const data = {
      notification_price: {
        users_quantity: pickedUsersLength,
        price: priceInfo.price,
        id: priceInfo.id,
      },
    };

    paymentsService.sendPaymentInitialization(data).then((res) => {
      priceInfo.price = res.data.product_notification.price;
      setNotificationPopup(true);
    });
  };

  const handlePickGenre = (e) => {
    if (e.target.checked === true) {
      let tempArray = filterGenre;
      tempArray.push(+e.target.value);
      setFilterGenre(tempArray);

      productsServices.getUsersToNotification(tempArray, startAge, endAge).then((res) => {
        setUsers(res.data);
      });
      return;
    }
    for (let i = 0; i < filterGenre.length; i++) {
      if (+e.target.value === filterGenre[i]) {
        let tempArray = filterGenre;
        tempArray.splice(i, 1);
        setFilterGenre(tempArray);
      }
      productsServices.getUsersToNotification(filterGenre, startAge, endAge).then((res) => {
        setUsers(res.data);
      });
    }
  };

  const handleResetFilters = () => {
    setFilterGenre([]);
    setStartAge(0);
    setEndAge(100);
    setPickedUsers([]);
    setPickedUsersLength(0);
  };

  return (
    <div className='w-full h-full flex-col pt-5 px-5'>
      <div className='text-2xl text-white font-bold my-5'>Benachrichtigungen</div>
      <div className='w-1/2 flex justify-between items-center'>
        <div className='text-lg text-white font-semibold'>
          Preis pro Benutzerbenachrichtigung: {priceInfo?.price}€
        </div>
        <button
          onClick={handleResetFilters}
          className='flex justify-center items-center text-white font-semibold'
        >
          Filter
          <MdOutlineClear color='orange' size='30px' />
        </button>
      </div>
      <div className='w-1/2 flex justify-between my-5'>
        <div className='w-5/12'>
          <div className='mb-3 text-white'>Nach Genre filtern:</div>
          <div className='h-[170px] pr-2 flex flex-col overflow-y-scroll'>
            {genres &&
              genres.map((genre) => (
                <div key={genre.id} className='flex justify-between'>
                  <label htmlFor={genre.id} className='text-white'>
                    {genre.name}
                  </label>
                  <input
                    type='checkbox'
                    id={genre.id}
                    name='genre'
                    value={genre.id}
                    onClick={handlePickGenre}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className='w-5/12'>
          <div className='mb-3 text-white'>Nach Alter filtern:</div>
          <div className='flex text-white'>
            <p className='flex mr-2'>Altersspanne</p>
            von
            <input
              type='number'
              min={0}
              max={99}
              value={startAge}
              className='border-2 border-green-accent w-12 mx-2 h-8 text-center bg-transparent'
              onChange={(e) => setStartAge(e.target.value)}
            />
            bis
            <input
              type='number'
              min={1}
              max={100}
              value={endAge}
              className='border-2 border-green-accent w-12 mx-2 h-8 text-center bg-transparent'
              onChange={(e) => setEndAge(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='py-3 px-3 border border-green-accent rounded-2xl'>
        <div className='mb-3 text-white font-semibold'>Users:</div>
        {users?.length <= 1 && (
          <div className='flex justify-center text-2xl text-orange-400 font-bold'>
            Keine Benutzer gefunden
          </div>
        )}
        {users?.length > 0 && (
          <div className='grid mx-auto grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-8 xl:grid-cols-12 gap-x-3 gap-y-2'>
            {users &&
              users
                .sort((a, b) => a.id - b.id)
                .map((user) => (
                  <div key={user.id}>
                    {user.id && (
                      <div
                        className={`w-fit flex flex-nowrap flex-shrink-0 justify-between items-center space-x-1 border rounded-md px-1
                        ${
                          pickedUsers.some((pickedUser) => pickedUser === user.id)
                            ? 'bg-green-accent text-black'
                            : 'text-white'
                        }`}
                      >
                        <label htmlFor={user.id} className='flex-shrink-0'>
                          ID: {user.id}
                        </label>
                        <input
                          type='checkbox'
                          id={user.id}
                          value={user.id}
                          name='checkedValue'
                          disabled={disabled}
                          onClick={handleClick}
                          className='flex-shrink-0 w-6 h-4 text-green-400'
                        />
                      </div>
                    )}
                  </div>
                ))}
          </div>
        )}
        <div className='w-full mt-5 flex flex-col justify-center items-center space-y-3 mb-5'>
          <div className='flex items-center space-x-3'>
            <label className='text-lg text-white font-semibold'>Wählen Sie alle Benutzer aus</label>
            <input
              type='checkbox'
              disabled={notificationPopup}
              className='flex items-center w-8 h-6 text-green-400'
              onClick={handleClickAll}
            />
          </div>
          <div className='flex text-white font-medium'>
            <div>Ausgewählte User:&nbsp;</div>
            <div>{pickedUsersLength}</div>
          </div>
          <button
            onClick={() => handleConfirm()}
            className='w-32 h-5 bg-green-accent rounded-xl shadow-md shadow-green-accent text-xs text-white font-semibold'
          >
            Bestätigen
          </button>
          {notificationPopup && (
            <div className='pt-10 flex flex-col'>
              <textarea
                placeholder='Benachrichtigungsnachricht...'
                className='border-2 border-green-accent rounded-lg px-1 h-32 w-72'
                onChange={(e) => {
                  setPromotionInfo({
                    product_notification: {
                      users: pickedUsers,
                      notification_text: e.target.value,
                      price: priceInfo.price,
                    },
                  });
                }}
              />
            </div>
          )}
          {notificationPopup && (
            <div className='mt-5 flex text-green-accent text-lg'>
              Gesamtpreis:&nbsp;
              <div className='font-bold'>{priceInfo.price}€</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPromotion;
