import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useRecoilValue} from 'recoil';
import {paymentsService} from '../../api/payments.service';
import {productsServices} from '../../api/products.service';
import {setPromotionAtom} from '../../atoms/promotions.atom';
import PartnerPromotion from './PartnerPromotion';
import ProductPromotion from './ProductPromotion';
import NotificationsPromotion from './NotificationsPromotion';
import ProfilePromotion from './ProfilePromotion';

const PromotionPage = () => {
  const [, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState();
  const [checkoutId, setCheckoutId] = useState();
  const [paymentId, setPaymentId] = useState();
  const [selectedTab, setSelectedTab] = useState('profile');

  const checkedPromotion = useRecoilValue(setPromotionAtom);

  const navigate = useNavigate();

  useEffect(() => {
    setError(false);
    setLoading(true);

    productsServices
      .getProducts()
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        if (!err.response || err.response.status === 404) {
          setError(err);
        }
      })
      .finally((_) => {
        setLoading(false);
      });
  }, [selectedTab, setSelectedTab]);

  const handleSend = (data) => {
    setLoading(true);
    setError(false);

    paymentsService
      .sendPayments(data)
      .then((res) => {
        setCheckoutId(res.data.checkout_id);
        setPaymentId(res.data.id);
      })
      .catch((err) => {
        if (!err.response || err.response.status === 404) {
          setError(true);
        }
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  checkoutId && paymentId && navigate(`/kaufoptionen/payments/${checkoutId}/${paymentId}`);

  return (
    <div className='w-full flex justify-center'>
      <div className='w-full bg-mirage max-w-7xl flex flex-col py-2 px-2 mt-8 rounded-xl'>
        <div className='flex justify-start items-center mt-5 space-x-5 px-5'>
          <button
            onClick={() => setSelectedTab('profile')}
            className={`w-44 h-8 bg-white shadow-sm shadow-green-accent text-sm text-black font-medium rounded-xl ${
              selectedTab === 'profile' && 'bg-green-accent text-white shadow-sm shadow-white'
            }`}
          >
            Profilfreischaltung
          </button>
          <button
            onClick={() => setSelectedTab('placement')}
            className={`w-44 h-8 bg-white shadow-sm shadow-green-accent text-sm text-black font-medium rounded-xl ${
              selectedTab === 'placement' && 'bg-green-accent text-white shadow-sm shadow-white'
            }`}
          >
            Platzierung
          </button>
          <button
            onClick={() => setSelectedTab('sponsorPlacement')}
            className={`w-44 h-8 bg-white shadow-sm shadow-green-accent text-sm text-black font-medium rounded-xl ${
              selectedTab === 'sponsorPlacement' &&
              'bg-green-accent text-white shadow-sm shadow-white'
            }`}
          >
            Vorreihung
          </button>
          <button
            onClick={() => setSelectedTab('notifications')}
            className={`w-44 h-8 bg-white shadow-sm shadow-green-accent text-sm text-black font-medium rounded-xl ${
              selectedTab === 'notifications' && 'bg-green-accent text-white shadow-sm shadow-white'
            }`}
          >
            Benachrichtigungen
          </button>
        </div>

        {selectedTab === 'profile' && <PartnerPromotion />}
        {selectedTab === 'placement' && <ProductPromotion />}
        {selectedTab === 'sponsorPlacement' && <ProfilePromotion />}
        {selectedTab === 'notifications' && (
          <NotificationsPromotion priceInfo={products?.notification_price} />
        )}
        {error && (
          <p className='text-center text-red-500 font-bold mt-10 ml-2'>
            Bitte wählen Sie ein Paket aus
          </p>
        )}

        <button
          onClick={() => handleSend(checkedPromotion)}
          className='mx-auto w-56 h-20 pb-1 my-8 bg-white rounded-xl shadow-md shadow-blue-300 text-2xl font-semibold'
        >
          Kaufen
        </button>

        {selectedTab === 'profile' && (
          <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
            Mit dem Freischalten eines Profils erhalten Sie die Kontaktdaten sowie nähere
            Informationen über die KünstlerInnen, um sie anschließend direkt über hear me oder
            privat kontaktieren zu können. Die Anzahl der Profilfreischaltungen ist abhängig vom
            jeweiligen Paket. Beim Paketkauf handelt es sich um Einmalzahlungen ohne Ablaufdatum und
            zusätzlicher Bindung.
          </div>
        )}
        {selectedTab === 'placement' && (
          <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
            Mit dem Kauf einer Platzierung wird Ihr Label für einen Monat bei jeder Anmeldung auf
            der Startseite angezeigt. Hierzu entscheidet die Platzierung die Reihenfolge des
            Aufscheinens. Es handelt sich um eine Einmalzahlung ohne zusätzliche Bindung.
          </div>
        )}
        {selectedTab === 'sponsorPlacement' && (
          <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
            Mit dem Kauf einer Vorreihung wird Ihr Label für einen Monat bei der Auflistung aller
            Kooperationspartner hervorgehoben. Alle drei Plätze treten gleichzeitig in Erscheinung.
            Es handelt sich um eine Einmalzahlung ohne zusätzliche Bindung.
          </div>
        )}
        {selectedTab === 'notifications' && (
          <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
            Informieren Sie eigens ausgewählte KünstlerInnen über aktuelle Events, Castings oder
            weitere Themen.
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotionPage;
