import PropTypes from "prop-types";

export default function BField({
  fieldName,
  placeholder,
  register,
  validation,
  type = "text",
  step = "1",
}) {
  return (
    <div>
      <input
        className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        placeholder={placeholder}
        step={step}
        {...register(fieldName, { ...validation })}
      />
    </div>
  );
}

BField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  type: PropTypes.string,
  register: PropTypes.func.isRequired,
};
