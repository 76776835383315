import React, {useEffect} from 'react';
import {Routes, Route, Navigate} from 'react-router';
import {songServices} from '../api/song.service';
import BSideBar from '../components/sideBars/SideBar';
import BAddPage from '../pages/AddPage';
import BEditUserPage from '../pages/EditUserPage';
import MessagePage from '../pages/messages/ConversationPage';
import MessageChat from '../pages/messages/MessageChatPage';
import BStatsPage from '../pages/stats/StatsPage';
import BSettingsPage from '../pages/settings/SettingsPage';
import BPaymentsResults from '../pages/payments/PaymentsResults';
import BPromotionPage from '../pages/promotions/PromotionPage';
import BPaymentsPage from '../pages/payments/PaymentsPage';
import BSongPreviewPage from '../pages/songPreviewPage/SongPreviewPage';
import BUserDetailPage from '../pages/user/UserDetailPage';
import BCompanyDataPage from '../pages/CompanyDataPage';
import BNewsPage from '../pages/NewsPage';
import SearchPage from '../pages/search/SearchPage';
import Player from '../pages/songPreviewPage/Player';
import Policy from '../pages/Policy';
import AGB from '../pages/AGB';
import RanksPage from '../pages/ranks/RanksPage';
import ContactsPage from '../pages/contact/ContactsPage';

export default function PrivateRoutes() {
  useEffect(() => {
    const songsProvider = () => songServices.getSongs();
    songsProvider();
  }, []); // eslint-disable-next-line

  return (
    <div className='w-full h-auto min-h-screen flex bg-gray-200 relative'>
      <BSideBar />
      <div className='flex flex-col w-full min-h-screen'>
        <div className='flex-grow bg-main relative w-full'>
          <Routes>
            <Route exact path='/' element={<Navigate to={'/nachrichten'} replace />} />
            <Route exact path='/kontakte' element={<ContactsPage />} />
            <Route exact path='/suchen' element={<SearchPage />} />
            <Route exact path='/nachrichten' element={<MessagePage />} />
            <Route exact path='/nachrichten/:id' element={<MessageChat />} />
            <Route exact path='/profil-bearbeiten' element={<BEditUserPage />} />
            <Route exact path='/impressum' element={<BCompanyDataPage />} />
            <Route exact path='/news' element={<BNewsPage />} />
            <Route exact path='/ranglisten' element={<RanksPage />} />
            <Route exact path='/statistiken' element={<BStatsPage />} />
            <Route exact path='/einstellungen' element={<BSettingsPage />} />
            <Route exact path='/kaufoptionen' element={<BPromotionPage />} />
            <Route exact path='/payments/:paymentId/results' element={<BPaymentsResults />} />
            <Route exact path='/lieder-vorhoren' element={<BSongPreviewPage />} />
            <Route exact path='/user/:id' element={<BUserDetailPage />} />
            <Route
              exact
              path='/kaufoptionen/payments/:checkoutId/:paymentId/'
              element={<BPaymentsPage />}
            />
            <Route exact path='/:entities/add' element={<BAddPage />} />
            <Route exact path='/:entities/:id/edit' element={<BEditUserPage />} />
            <Route exact path='/Policy' element={<Policy />} />
            <Route exact path='/AGB' element={<AGB />} />
          </Routes>
        </div>
        <div className='sticky bottom-0'>
          <Player />
        </div>
      </div>
    </div>
  );
}
