import {useMemo} from 'react';
import {useTable, useBlockLayout, useResizeColumns} from 'react-table';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import BTableCell from '../tables/TableCell';
import AppAudioPlayer from '../../pages/songPreviewPage/TracksPlayer';
import './tableRenderer.css';

function BTableRendrer({spacePerColumn, columns, data, addOrdering, ordering, maxNmbOfChars, isReadOnly}) {
  const defaultColumn = useMemo(() => {
    const rightScrollbarWidth = 15;

    let columnsWidths = {
      minWidth: 150,
      maxWidth: 1000,
    };

    if (spacePerColumn !== 0) {
      columnsWidths = {
        ...columnsWidths,
        width: spacePerColumn - rightScrollbarWidth,
      };
    }

    return columnsWidths;
  }, [spacePerColumn]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns
  );

  const showArrowBasedOnOrdering = (field) => {
    field = field.replace(/([A-Z])/g, '_$1').toLowerCase();

    if (ordering.includes(field)) {
      return <IoIosArrowUp color='white' />;
    } else if (ordering.includes(`-${field}`)) {
      return <IoIosArrowDown color='white' />;
    }
    return null;
  };

  return (
    <>
      <div className='w-full mt-2 overflow-x-scroll h-full'>
        <div {...getTableProps()} className='table border border-gray-300'>
          <div className='bg-green-accent'>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className='tr' key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <div
                    key={column.id}
                    {...column.getHeaderProps()}
                    className='th m-0 relative p-2 font-bold text-white'
                    onClick={() => addOrdering(column.id)}
                  >
                    <div className='flex justify-between'>
                      {column.render('Header')}
                      {showArrowBasedOnOrdering(column.id)}
                    </div>

                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className='overflow-auto w-full'>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className='hover:bg-gray-200'>
                  {row.cells.map((cell, idx) => {
                    if (cell.column.id === 'file') {
                      return (
                        <AppAudioPlayer
                          tak={cell.row.original}
                          isDefaultShuffle={false}
                          tracks={cell.value}
                          firstTrackIndex={0}
                        />
                      );
                    }

                    return <BTableCell isReadOnly={isReadOnly} cell={cell} maxChars={maxNmbOfChars} />;
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BTableRendrer;
