import React from 'react';
import BForm from '../components/forms/Form';

export default function CompanyDataForm({
  onSubmit,
  btnText,
  preloadedData,
  success,
  responseErrors,
}) {
  const fields = [
    {
      fieldName: 'company_name',
      placeholder: 'Firmenwortlaut',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'company_subject',
      placeholder: 'Unternehmensgegenstand',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'vat_number',
      placeholder: 'UID-Nummer',
      validation: {
        required: false,
      },
    },
    {
      fieldName: 'company_number',
      placeholder: 'Firmenbuchnummer',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'district_court',
      placeholder: 'Firmenbuchgericht',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'street',
      placeholder: 'Firmensitz',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'post_code',
      placeholder: 'PLZ',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'city',
      placeholder: 'Stadt',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'phone_number',
      placeholder: 'Telefonnummer',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'email',
      placeholder: 'E-mail',
      validation: {
        required: true,
      },
    },
  ];

  return (
    <BForm
      success={success}
      responseErrors={responseErrors}
      preloadedData={preloadedData}
      btnText={btnText}
      submit={onSubmit}
      fields={fields}
      fieldColor="text-green-accent"
    />
  );
}
