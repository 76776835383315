import playIcon from '../assets/player/play.svg';

export const checkMusicFile = (message, handleUserClick) => {
  const prefix = 'https://hearme-app.com/api/uploads/audio/';
  const suffix = '.mp3';

  if (message?.startsWith(prefix) && message?.endsWith(suffix)) {
    return (
      <button
        type='button'
        className='hover:cursor-pointer hover:text-green-accent hover:underline hover:pb-1'
        onClick={handleUserClick}
      >
        Audiodatei
        <img
          className='w-10 h-10 hover:cursor-pointer hover:text-green-accent hover:underline hover:pb-1'
          src={playIcon}
        />
      </button>
    );
  } else {
    return message;
  }
};
