import {createFormData} from '../utils/formDataHelper';
import httpClient from './http.client';

function getAll(endpoint) {
	return httpClient.get(endpoint);
}

function add(endpoint, newItem) {
	return httpClient.post(endpoint, newItem);
}

function addForm(endpoint, newItem) {
	const data = createFormData(newItem);
	return httpClient.post(endpoint, data);
}

function getById(endpoint, id) {
	return httpClient.get(endpoint + `${id}/`);
}

function update(endpoint, id, newItem) {
	if (id) {
		return httpClient.patch(`${endpoint + id}/`, newItem);
	}
	return httpClient.patch(endpoint, newItem);
}

function updateForm(endpoint, newItem) {
	const data = createFormData(newItem);
	return httpClient.patch(endpoint, data);
}

function remove(endpoint, id) {
	return httpClient.delete(endpoint);
}

export const crudService = {
	getAll,
	add,
	addForm,
	getById,
	update,
	updateForm,
	remove,
};
