import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import BFieldWrapper from './FieldWrapper';

export default function BForm({
  preloadedData,
  fields,
  btnText,
  submit,
  scroll = 'overflow-y-scroll',
  clearFormAfterSubmit = false,
  responseErrors,
  success,
  fieldColor,
}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm({defaultValues: preloadedData});

  const onSubmit = (data) => {
    if (clearFormAfterSubmit) {
    }

    if (agreePrivacy) data.agree = 1;
    else data.agree = 0;

    return submit(data);
  };

  const [agreePrivacy, setAgreePrivacy] = React.useState(false);
  const handleAgreePrivacyChange = () => {
    setAgreePrivacy(!agreePrivacy);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`w-full h-full px-6 pb-1 max-h-85vh ${scroll}`}
    >
      {fields &&
        fields.map((f) => (
          <BFieldWrapper
            key={f.fieldName}
            field={f}
            value={f.value}
            errors={errors}
            register={register}
            control={control}
            fieldColor={fieldColor}
          />
        ))}
      {btnText === 'Registrierung' ? (
        <label>
          <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
            <input
              type='checkbox'
              checked={agreePrivacy}
              onChange={handleAgreePrivacyChange}
              name='agree'
            />
            &nbsp; Ich bestätige hiermit, dass ich die{' '}
            <a href='/policies-artist.html'>
              <u>AGB</u>
            </a>{' '}
            und{' '}
            <a href='/policies.html'>
              <u>Datenschutzbestimmung</u>
            </a>{' '}
            gelesen habe und akzeptiere.
          </div>
        </label>
      ) : (
        ''
      )}

      <div className='md:flex md:items-center'>
        <div className='w-full flex items-center justify-center mt-3'>
          <input
            className='shadow bg-green-accent cursor-pointer hover:bg-blue-hover normal-case
            focus:shadow-outline focus:outline-none text-white font-bold py-1 px-3 rounded mx-auto'
            type='submit'
            value={btnText}
          />
        </div>
      </div>
      {responseErrors &&
        responseErrors.map((errorMessage, index) => (
          <div key={index} className='p-2 text-red-600 text-center'>
            {errorMessage}
          </div>
        ))}
      {success && (
        <div className='p-2 text-green-600 text-center'>
          {typeof success === 'string' ? success : 'Erflog!'}
        </div>
      )}
    </form>
  );
}

BForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
      validation: PropTypes.object,
      type: PropTypes.string,
    })
  ),
  btnText: PropTypes.string,
  submit: PropTypes.func,
};
