export const preloadedDataFormatter = (preloadedData, userData) => {
  preloadedData.name = preloadedData.user.name;
  preloadedData.sex = preloadedData.user.sex;
  preloadedData.birth_date = preloadedData.user.birth_date;
  preloadedData.address = userData.address;
  preloadedData.phone = userData.phone;
  preloadedData.description = userData.description;

  
  preloadedData.genresName = [];
  for (let i = 0; i < preloadedData.user.genre.length; i++) {
    preloadedData.genresName.push({
      label: preloadedData.user.genre[i].name,
      value: preloadedData.user.genre[i].id,
    });
  }

  preloadedData.languagesName = [];
  for (let i = 0; i < preloadedData.user.language.length; i++) {
    preloadedData.languagesName.push({
      label: preloadedData.user.language[i].name,
      value: preloadedData.user.language[i].id,
    });
  }
};

export const filledDataFormatter = (data) => {
  data.genres = [];
  data.languages = [];

  if (data.genresName.length > 0 && data.genresName[0].value) {
    for (let i = 0; i < data.user.genre.length; i++) {
      data.user.genre[i] = data.genresName[i].value;
      data.genres.push(data.genresName[i].value);
    }
  } else data.genres = data.genresName;

  if (data.languagesName.length > 0 &&  data.languagesName[0].value) {
    for (let i = 0; i < data.user.language.length; i++) {
      data.user.language[i] = data.languagesName[i].value;
      data.languages.push(data.languagesName[i].value);
    }
  } else data.languages = data.languagesName;
};