import {atom} from 'recoil';

export const playerVisibilityAtom = atom({
  key: 'playerVisibilityAtom',
  default: false,
});

export const tracksListAtom = atom({
  key: 'trackListAtom',
  default: [],
});

export const currentTrackIdAtom = atom({
  key: 'currentTrackIdAtom',
  default: 0,
});

export const playingAtom = atom({
  key: 'isPlayingAtom',
  default: false,
});
