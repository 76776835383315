import {arrayConverterToQuery} from '../utils/arrayToQueryConverter';
import httpClient from './http.client';

const USERS_ENDPOINT_URL = 'user/';

const getAllUsers = () => {
  return httpClient.get('users/');
};

const followUser = (userId) => {
  return httpClient.post(`${USERS_ENDPOINT_URL}follow/`, {
    followed: userId,
  });
};

const unfollowUser = (userId) => {
  return httpClient.post(`${USERS_ENDPOINT_URL}unfollow/`, {
    followed: userId,
  });
};

const getCurrentUser = () => {
  return httpClient.get('current-user/');
};

const updateCurrentUser = (user) => {
  return httpClient.put('user/update/', user);
};

const getOtherUserProfile = (userId) => {
  return httpClient.get(`users/${userId}/`);
};

const getTopArtists = async () => {
  const response = await httpClient.get('/top/artist/');
  return response;
};

const getTopUnlockedArtists = async () => {
  const response = await httpClient.get('/top/most_unlocked_artists');
  return response;
};

const getTopTracks = async () => {
  const response = await httpClient.get('/top/track/');
  return response;
};

const getTopListenedTracks = async () => {
  const response = await httpClient.get('/top/most_listened_tracks');
  return response;
};

// lack of endpoint artists, would be great to have it
// const getArtists = async () => {
// 	const response = await httpClient.get('/backoffice/artists/');
// 	return response;
// };

const getArtist = async () => {
  const response = await httpClient.get('/artist/');
  return response;
};

const getArtistDetails = async (id) => {
  const response = await httpClient.get(`/users/${id}/`);
  return response;
};

const getUsers = async () => {
  const response = await httpClient.get('/users/');
  return response;
};

const getUsersById = async (id) => {
  const response = await httpClient.get(`/search/?s=${id}`);
  return response;
};

const getFilteredUsers = async (languages, genres, sex, age_min, age_max) => {
  genres.length === 0 && (genres = '');
  languages.length === 0 && (languages = '');
  const response = await httpClient.get(
    `/users/?age_min=${age_min ? age_min : 1}&age_max=${age_max ? age_max : 99}${
      languages && arrayConverterToQuery('language', languages)
    }${genres && arrayConverterToQuery('genre', genres)}${sex ? '&sex=' + sex : ''}`
  );

  return response;
};

const unlockProfile = async (id) => {
  /*alert(id);*/
  const response = await httpClient.post(`/user/unlock-profile/`, {id});
  return response;
};

const lockProfile = async (id) => {
  /*alert(id);*/
  const response = await httpClient.post(`/user/lock-profile/`, {id});
  return response;
};

export const usersService = {
  getAllUsers,
  followUser,
  unfollowUser,
  getCurrentUser,
  updateCurrentUser,
  getOtherUserProfile,
  getTopArtists,
  getTopUnlockedArtists,
  getTopTracks,
  getTopListenedTracks,
  getArtist,
  getUsers,
  getUsersById,
  getFilteredUsers,
  getArtistDetails,
  unlockProfile,
  lockProfile,
};
