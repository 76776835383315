import React, {useCallback, useEffect, useRef} from 'react';

export const Modal = ({message, isOpen, onClose, onConfirm}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) =>
      modalRef.current && !modalRef.current.contains(event.target) && onClose();

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      return;
    }
    document.removeEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center'>
      <div ref={modalRef} className='bg-white p-4 rounded shadow-md max-w-sm w-full'>
        <h2 className='text-justify text-xl mb-4'>{message}</h2>
        <div className='flex justify-end gap-x-4'>
          <button
            className='w-1/4 bg-gray-300 hover:bg-green-accent text-gray-800 font-bold py-2 px-4 rounded'
            onClick={onClose}
          >
            Nein
          </button>
          <button
            className='w-1/4 bg-red-500 hover:bg-red-900 text-white font-bold py-2 px-4 rounded'
            onClick={onConfirm}
          >
            Ja
          </button>
        </div>
      </div>
    </div>
  );
};
