import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';
import {playerVisibilityAtom, playingAtom, tracksListAtom} from '../../atoms/tracks.atom';

const usePlayerVisibility = () => {
  const setPlayerVisible = useSetRecoilState(playerVisibilityAtom);
  const setTrackList = useSetRecoilState(tracksListAtom);
  const setIsPlaying = useSetRecoilState(playingAtom);

  useEffect(() => {
    setPlayerVisible(true);

    return () => {
      setPlayerVisible(false);
      setTrackList([]);
      setIsPlaying(false);
    };
  }, [setPlayerVisible]);
};

export default usePlayerVisibility;
