import React from 'react';

export const WhiteFrame = ({content, customBorder}) => {
  return (
    <div>
      <div className={`h-content p-6 m-6 bg-white ${customBorder ? customBorder : 'rounded-md'} `}>
        <div>{content}</div>
      </div>
    </div>
  );
};
