import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {crudService} from '../api/crud.service';
import {filledDataFormatter, preloadedDataFormatter} from '../utils/preloadedDataFormatter';
import BFormWrapper from '../components/forms/FormWrapper';
import BLoadingIndicator from '../components/indicators/LoadingIndicator';
import {BNavigateBox} from '../components/navigateBox/NavigateBox';

export default function BEditUserPage() {
  const {entities} = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [userData, setUserData] = useState();
  const [item, setItem] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    setIsLoading(true);
    setResponseErrors([]);
    crudService.getAll('/partners').then((response) => {
      crudService.getAll('/current-user').then((res) => {
        // eslint-disable-next-line
        response.data.map((x) => {
          if (x.user.id === res.data.id) {
            setId(x.id);
          }
        });
        setUserData({
          address: res.data.address,
          birth_date: res.data.birth_date,
          phone: res.data.phone,
          description: res.data.description,
        });
      });
    });
  }, []);

  useEffect(() => {
    id &&
      userData &&
      crudService
        .getAll(`/partners/${id}/`)
        .then((res) => {
          setItem(res.data);
          item &&
            userData &&
            setItem((prevItem) => ({
              ...prevItem,
              address: userData.address,
              birth_date: userData.birth_date,
              phone: userData.phone,
              description: userData.description,
            }));
        })
        .catch((err) => {
          if (err.response.data) {
            const tempErrors = responseErrors;
            tempErrors.push(err.response.data);
            setResponseErrors(tempErrors);
          }
        })
        .finally(() => setIsLoading(false)); // eslint-disable-next-line
  }, [id, userData]);

  const handleSubmit = (data) => {
    const photo = data.avatar;
    delete data.avatar;
    filledDataFormatter(data);
    setIsLoading(true);
    setResponseErrors([]);

    crudService.update(`/user/update/`, null, data).catch((err) => {
      if (err.response.data) {
        const tempErrors = responseErrors;
        tempErrors.push(err.response.data);
        setResponseErrors(tempErrors);
      }
    });

    if (photo) {
      crudService.updateForm(`/user/update/`, {avatar: photo}).catch((err) => {
        if (err.response.data) {
          const tempErrors = responseErrors;
          tempErrors.push(err.response.data);
          setResponseErrors(tempErrors);
        }
      });
    }

    crudService
      .update(`/partners/`, data.id, {
        website: data.website,
        company: data.company,
      })

      .catch((err) => {
        if (err.response.data) {
          const tempErrors = responseErrors;
          tempErrors.push(err.response.data);
          setResponseErrors(tempErrors);
        }
      })
      .finally((_) => {
        const refresh = () => {
          // window.location.reload(true);
          setIsLoading(false);
        };
        refresh();
      });
  };

  if (isLoading) {
    return <BLoadingIndicator />;
  }

  if (item && item.detail) setItem(null);
  item && preloadedDataFormatter(item, userData);

  return (
    <>
      <BNavigateBox title={'Profil bearbeiten'} />
      <div className='m-6 bg-white mt-7 py-5 border-gray-400 rounded shadow-md'>
        <BFormWrapper
          name={entities}
          preloadedData={item}
          responseErrors={responseErrors}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
}
