import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useSetRecoilState} from 'recoil';
import Ratings from 'react-ratings-declarative';
import {usersService} from '../../api/users.service';
import {tracksListAtom} from '../../atoms/tracks.atom';
import {partnerService} from '../../api/partners.service';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';
import usePlayerVisibility from '../../components/hooks/usePlayerVisibility';
import {useCustomNavigate} from '../../components/hooks/useCustomNavigate';
import {ageCategoryPicker, calculateAgeFromDateBirth} from '../../utils/calculateAgeFromDateBirth';
import UserTrackList from './UserTrackList';

import fanIcon from '../../assets/fan.svg';
import maleIcon from '../../assets/male.svg';
import femaleIcon from '../../assets/female.svg';
import transIcon from '../../assets/trans.svg';

const BUserDetailPage = () => {
  const {id} = useParams();
  usePlayerVisibility();
  const handleRouteClick = useCustomNavigate();
  const setPlayerTrackListState = useSetRecoilState(tracksListAtom);

  const [user, setUser] = useState();
  const [tracks, setTracks] = useState([]);
  const [isContracted, setIsContracted] = useState(null);
  const [userRating, setUserRating] = useState(2);

  const isContact = user?.is_contact;
  const isUnlocked = user?.is_unlocked;

  useEffect(() => {
    const fetchUser = async () => {
      const userRes = await usersService.getOtherUserProfile(+id);
      setUser(userRes.data);

      const allTracks = [];
      const allAlbums = [];

      userRes.data?.artist?.albums.forEach((album) => allAlbums.push(album));

      allAlbums.forEach((album) => {
        album.tracks.forEach((track) => {
          const trackWithAlbum = {...track, albumName: album.name};
          allTracks.push(trackWithAlbum);
        });
      });

      setTracks(allTracks);
      setPlayerTrackListState(allTracks);

      const contractedRes = await partnerService.getContracted();
      const userExists = contractedRes.data.some((c) => c.id === userRes.data.id);
      setIsContracted(userExists);

      if (userRes.data?.artist?.rating > 0) setUserRating(userRes.data?.artist?.rating);
    };

    fetchUser(); // eslint-disable-next-line
  }, []);

  const maskEmail = (email) => {
    const [user, domain] = email.split('@');
    const maskedUser = '*'.repeat(user.length);

    return `${maskedUser}@${domain}`;
  };

  const handleUnlockUser = async () => {
    let hasError = false;

    try {
      const request = await usersService.unlockProfile(user.id);
      return request;
    } catch (error) {
      hasError = true;
    } finally {
      window.location.reload();
    }
  };

  const handleContractUser = () => {
    partnerService.acceptContract(user.id).then(() => window.location.reload());
  };

  const sexIcons = {
    male: maleIcon,
    female: femaleIcon,
    X: transIcon,
  };

  const buttonClass =
    'w-40 p-2 border rounded-2xl font-semibold hover:p-3' +
    'hover:w-28 hover:bg-green-accent hover:text-mirage';

  const circleFilled = (
    <svg width='40' height='40'>
      <circle cx='20' cy='20' r='16' fill='#119783' />
    </svg>
  );

  const circleOutlined = (
    <svg width='40' height='40'>
      <circle cx='20' cy='20' r='16' fill='none' stroke='#119783' strokeWidth='2.4' />
    </svg>
  );

  return (
    <>
      <div className='p-5'>
        <BNavigateBox title='Nutzerinformation' />
        {user && (
          <div className='flex justify-around items-center pt-20'>
            <div className='w-1/3 h-[280px] flex flex-col justify-around items-center'>
              <img
                alt='avatar'
                src={user.avatar ? user.avatar : fanIcon}
                className='w-28 bg-black'
              />
              <Ratings rating={userRating}>
                {Array.from({length: 5}, (_, index) => (
                  <Ratings.Widget
                    key={index}
                    svg={index < userRating ? circleFilled : circleOutlined}
                  />
                ))}
              </Ratings>
            </div>
            <div className='w-2/3 flex flex-col justify-center items-start'>
              <div className='text-txt1-accent flex font-bold text-xl'>
                ID:&nbsp;
                <div className='text-white'>{user.id}</div>
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Geschlecht:&nbsp;
                <img alt='sex' src={sexIcons[user.sex]} />
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Stimme:&nbsp;
                <img alt='sex' src={sexIcons[user.voice]} />
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Name:&nbsp;
                <div className='text-white '>{isUnlocked ? user.name : '*********'}</div>
              </div>

              <div className='text-txt1-accent flex font-bold text-xl'>
                E-mail:&nbsp;
                <div className='text-white '>{isUnlocked ? user.email : maskEmail(user.email)}</div>
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Rolle:&nbsp;
                <div className='text-white'>{user.role}</div>
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Alter:&nbsp;
                <div className='text-white'>
                  {isUnlocked
                    ? ageCategoryPicker(calculateAgeFromDateBirth(user.birth_date))
                    : '***'}
                </div>
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Besucher:&nbsp;
                <div className='text-white '>{user.visitors}</div>
              </div>
              <div className='text-txt1-accent flex font-bold text-xl'>
                Registrierdatum:&nbsp;
                <div className='text-white '>
                  {isUnlocked ? user.register_date.slice(0, 10) : '****-**-**'}
                </div>
              </div>
              {isUnlocked && user.genre.length !== 0 && (
                <div className='text-txt1-accent flex font-bold text-xl'>
                  Genres:&nbsp;
                  <div className='text-white '>
                    {user.genre.map((genre, idx) => (idx === 0 ? genre.name : ', ' + genre.name))}
                  </div>
                </div>
              )}
              {isUnlocked && user.language.length !== 0 && (
                <div className='text-txt1-accent flex font-bold text-xl'>
                  Sprachen:&nbsp;
                  <div className='text-white '>
                    {user.language.map((language, idx) =>
                      idx === 0 ? language.name : ', ' + language.name
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='w-1/5 flex flex-col justify-start text-green-accent gap-4'>
              {isUnlocked && (
                <button
                  type='button'
                  className={buttonClass}
                  onClick={() => handleRouteClick(`/nachrichten/${user.id}`)}
                >
                  Nachricht
                </button>
              )}
              {isContact && !isUnlocked && (
                <button type='button' className={buttonClass} onClick={handleUnlockUser}>
                  Profilfreischaltung
                </button>
              )}
              {isUnlocked && !isContracted && (
                <button type='button' className={buttonClass} onClick={handleContractUser}>
                  Unter Vertrag
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {tracks.length > 0 && <UserTrackList tracks={tracks} />}
    </>
  );
};

export default BUserDetailPage;
