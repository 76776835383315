import React, {useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {setPromotionAtom} from '../../atoms/promotions.atom';

import activeFirstPack from '../../assets/promotions/active/active_first_pack.svg';
import activeSecondPack from '../../assets/promotions/active/active_second_pack.svg';
import activeThirdPack from '../../assets/promotions/active/active_third_pack.svg';
import activeFourthPack from '../../assets/promotions/active/active_fourth_pack.svg';
import activeFifthPack from '../../assets/promotions/active/active_fifth_pack.svg';

import inactiveFirstPack from '../../assets/promotions/inactive/inactive_first_pack.svg';
import inactiveSecondPack from '../../assets/promotions/inactive/inactive_second_pack.svg';
import inactiveThirdPack from '../../assets/promotions/inactive/inactive_third_pack.svg';
import inactiveFourthPack from '../../assets/promotions/inactive/inactive_fourth_pack.svg';
import inactiveFifthPack from '../../assets/promotions/inactive/inactive_fifth_pack.svg';

/// Purchase user unlocks
const PartnerPromotion = () => {
  const [clicked, setClicked] = useState();
  const setPromotionInfo = useSetRecoilState(setPromotionAtom);

  const handleClickPromotion = (id) => {
    setClicked(id);
    setPromotionInfo({product_profile_unlock: +id});
  };

  const buttons = [
    {id: 2, alt: 'second', activeSrc: activeSecondPack, inactiveSrc: inactiveSecondPack},
    {id: 3, alt: 'third', activeSrc: activeThirdPack, inactiveSrc: inactiveThirdPack},
    {id: 1, alt: 'first', activeSrc: activeFirstPack, inactiveSrc: inactiveFirstPack},
    {id: 4, alt: 'fourth', activeSrc: activeFourthPack, inactiveSrc: inactiveFourthPack},
    {id: 5, alt: 'fifth', activeSrc: activeFifthPack, inactiveSrc: inactiveFifthPack},
  ];

  return (
    <div className='w-full h-full flex-col pt-5 pb-10 px-5'>
      <div className='text-2xl text-white font-bold my-5'>Profilfreischaltung</div>
      <div className='flex justify-center items-end space-x-2 mt-16'>
        {buttons.map((button) => (
          <button key={button.id} onClick={() => handleClickPromotion(button.id)}>
            <img
              alt={button.alt}
              src={clicked === button.id ? button.activeSrc : button.inactiveSrc}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default PartnerPromotion;
