export function createFormData(formValues) {
	const objectKeys = Object.keys(formValues);
	let formData = new FormData();
	objectKeys.forEach((key) => {
		if (key === 'file' && typeof formValues[key] !== 'string') {
			formData.append(key, formValues[key], formValues[key].name);
			return;
		}
		formData.append(key, formValues[key]);
	});
	return formData;
}
