import React, {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types';
import {usersService} from '../../api/users.service';

export default function BFileInput({
  placeholder,
  fieldName,
  control,
  btnText = 'Bild hinzufügen',
  availbaleSizeInMB = 10,
  availableExtensions = ['image/jpg', 'image/png', 'image/gif'],
}) {
  const [imageSrc, setImageSrc] = useState('');
  const imageInput = useRef();
  const ONE_MB_IN_BYTES = 1048576;

  useEffect(() => {
    usersService.getCurrentUser().then((res)=>{
      setImageSrc(res.data.avatar);
    })
  }, []);

  const handleFileInputChange = (e) => {
    if (e == null) {
      return null;
    }

    const fileFromInput = e.target.files[0];
    const fileExtension = fileFromInput.name.split('.')[1];

    if (!isExtensionAllowed(fileExtension)) {
      alert('Wrong file extension!');
      return;
    }

    if (fileFromInput.size > availbaleSizeInMB * ONE_MB_IN_BYTES) {
      alert('File is too large!');
      return;
    }

    setImageSrc(URL.createObjectURL(fileFromInput));
    return fileFromInput;
  };

  const isExtensionAllowed = (fileExtension) => {
    const tempExtensions = availableExtensions.map((ex) => ex.split('/')[1]);
    return tempExtensions.includes(fileExtension);
  };

  return (
    <div className='flex items-center space-y-2 flex-col shadow appearance-none bg-white border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline'>
      {imageSrc && (
        <>
          <h2 className='text-l px-2 py-2'>Aktuelles bild</h2>
          <img className='mx-auto border-2' alt='Current' width={150} height={150} src={imageSrc} />
        </>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({field: {onChange}}) => (
          <>
            <input
              className='hidden'
              type='file'
              placeholder={placeholder}
              onChange={(e) => onChange(handleFileInputChange(e))}
              ref={imageInput}
              accept={availableExtensions.join(', ')}
            />
            <button
              onClick={() => imageInput.current.click()}
              type='button'
              className='appearance-nonerounded py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline bg-gray-100 hover:bg-gray-300 transition-all rounded w-1/4'
            >
              {btnText}
            </button>
          </>
        )}
        rules={{required: false}}
      />
    </div>
  );
}

BFileInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};
