import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from 'recoil';
import Router from './routes/Router';

import './index.css';
import 'react-icons/ai';
import 'react-icons/bi';
import 'react-icons/bs';
import 'react-icons/cg';
import 'react-icons/di';
import 'react-icons/fa';
import 'react-icons/fc';
import 'react-icons/fi';
import 'react-icons/gi';
import 'react-icons/go';
import 'react-icons/gr';
import 'react-icons/hi';
import 'react-icons/im';
import 'react-icons/io';
import 'react-icons/io5';
import 'react-icons/md';
import 'react-icons/ri';
import 'react-icons/si';
import 'react-icons/ti';
import 'react-icons/vsc';
import 'react-icons/wi';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
