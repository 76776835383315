import {useEffect, useState} from 'react';
import capitalizeFieldHeader from '../../utils/capitalizeFieldHeader';
import BTableRendrer from '../renderers/TableRenderer';
import BTableDropdown from './TableDropdown';
import BTableHeader from './TableHeader';
import translateFieldHeader from '../../utils/translateFieldHeader';

function BTable({
  data,
  header,
  addOrdering,
  title,
  ordering,
  availableWidth,
  maxNmbOfChars,
  fieldsVisibleAtBeginning,
  isReadOnly = false,
}) {
  const [fields, setFields] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);

  useEffect(() => {
    if (data == null || data.length === 0) {
      setFields([]);
      return;
    }

    let tempFields = [];
    let tempVisibleFields = [];

    for (const [key] of Object.entries(data[0])) {
      const fieldPair = {accessor: key, Header: translateFieldHeader(capitalizeFieldHeader(key))};
      tempFields.push(fieldPair);

      if (fieldsVisibleAtBeginning.includes(key)) {
        tempVisibleFields.push(fieldPair);
      }
    }

    setFields(tempFields);
    setVisibleFields(tempVisibleFields);

    if (tempVisibleFields.length === 0) {
      setVisibleFields(tempFields);
    }
    // eslint-disable-next-line
  }, [data]);

  const onToggleField = (field) => {
    let tempFields = visibleFields;
    tempFields = tempFields.filter((val) => val !== field);
    if (tempFields.length === visibleFields.length) {
      tempFields.push(field);
    }
    setVisibleFields(tempFields);
  };

  //   const resetVisibleFields = () => {
  //     setVisibleFields(fields);
  //   };

  const computeColumnSpace = () => {
    let visibleFields = fieldsVisibleAtBeginning.length === 0 ? fields : fieldsVisibleAtBeginning;

    if (visibleFields.length === 2) {
      return availableWidth / visibleFields.length - 20;
    }

    return visibleFields.length !== 0 ? availableWidth / visibleFields.length : 0;
  };

  const colSpace = computeColumnSpace();

  return (
    <>
      <BTableHeader header={header} />
      <div
        className={`bg-white rounded shadow-md max-w-[1660px] h-[90vh] flex flex-col border-gray-400 py-2 px-3 mt-6`}
      >
        <div className='flex justify-end flex-grow-0'>
          <BTableDropdown fields={fields} onToggleField={onToggleField} visibleFields={visibleFields} />
        </div>
        <BTableRendrer
          columns={visibleFields}
          data={data}
          addOrdering={addOrdering}
          title={title}
          ordering={ordering}
          maxNmbOfChars={maxNmbOfChars}
          spacePerColumn={colSpace}
          isReadOnly={isReadOnly}
        />
      </div>
    </>
  );
}

export default BTable;
