import { BsTable } from "react-icons/bs";

const BTableHeader = ({ header }) => {
  return (
    <div className="bg-green-accent absolute z-10 rounded py-3 px-4 ml-3 flex items-center">
      <BsTable className="text-white" />
      <h3 className="text-white text-xl ml-3 my-0 font-normal">{header}</h3>
    </div>
  );
};

export default BTableHeader;
