import React from 'react';
import UserForm from '../../forms/user.form';

export default function BFormWrapper({name, preloadedData, onSubmit, responseErrors, success}) {
	const getForm = () => {
		return (
			<UserForm
				btnText='Benutzer speichern'
				onSubmit={onSubmit}
				preloadedData={preloadedData}
				responseErrors={responseErrors}
				success={success}
			/>
		);
	};

	return <>{getForm()}</>;
}
