import React, {useState} from 'react';
import {useEffect} from 'react';
import {useParams} from 'react-router';
import {paymentsService} from '../../api/payments.service';
import {messagesService} from '../../api/messages.service';

const BPaymentsResults = () => {
  const {paymentId} = useParams();
  const [error, setError] = useState(false);
  const [resultText, setResultText] = useState('');
  const [resultData, setResultData] = useState();

  useEffect(() => {
    setError(false);

    const fetchAndSendMessages = async () => {
      const paymentRes = await paymentsService.getPaymentsResults(paymentId);
      setResultData(paymentRes.data);
      setResultText('Erfolg! Die Bestellung wurde bezahlt');
      try {
      } catch (error) {
        setResultText('Bezahlung fehlgeschlagen!');
        if (!error.response || error.response.status === 404) {
          setResultText('Bezahlung erfolgreich!');
        }
        setError(true);
      } finally {
        const selectedUsers = paymentRes.data?.product_notification?.users;
        const notificationText = paymentRes.data?.product_notification?.notification_text;

        if (selectedUsers?.length > 0 && notificationText != null) {
          for (let i = 0; i < selectedUsers.length; i++) {
            await messagesService.sendMessage({
              content: notificationText,
              receiver: selectedUsers[i],
            });
          }
        }
      }
    };

    fetchAndSendMessages();
  }, []); // eslint-disable-next-line

  return (
    <>
      <div className='bg-white rounded shadow-md min-h-[50%] border-gray-400 py-2 px-3 mt-6 m-6'>
        <div className='h-full flex justify-center items-center'>
          <div className='mx-5'>
            <p className='text-center text-lg'>Aktueller Stand der Zahlungen:</p>
            <p className='text-xl text-center mt-3 underline text-green-accent'>{resultText}</p>
          </div>
        </div>
        {!error && resultData && (
          <div className='ml-3'>
            <p className='font-bold mt-5'>Einzelheiten der Bestellung: </p>
            <p>
              Summe: <p className='font-bold'>{resultData.amount}€</p>
            </p>
            <p>
              Bestellnummer:
              <p className='font-bold'>{resultData.checkout_id}</p>
            </p>
            <p>
              Bestellstatus: <p className='font-bold'>{resultData.status}</p>
            </p>
            {resultData.valid_from && (
              <p>
                Gültig ab:
                <p className='font-bold'>{resultData.valid_from.slice(0, 10)}</p>
              </p>
            )}
            {resultData.valid_until && (
              <p>
                Gültig bis:
                <p className='font-bold'>{resultData.valid_until.slice(0, 10)}</p>
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BPaymentsResults;
