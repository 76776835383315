import React from 'react';

export default function BErrorIndicator() {
  return (
    <div className='p-6'>
      <div className='bg-white rounded shadow-md mt-6 w-full'>
        <div className='p-10 font-bold text-lg text-center'>Noch keine Daten</div>
      </div>
    </div>
  );
}
