import axios from 'axios';
import {authService} from './auth.service';

const httpClient = axios.create({
  baseURL: 'https://hearme-app.com/api',
});

httpClient.interceptors.request.use(function (config) {
  authService.tokenValue
    ? (config.headers.Authorization = `Bearer ${authService.tokenValue}`)
    : (config.headers.Authorization = '');
  return config;
});

httpClient.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    authService.refreshAccessToken().then((res) => {
      error.config.headers.Authorization = `Bearer ${authService.tokenValue}`;
      return axios.request(error.config);
    });
  }
  return Promise.reject(error);
});

export default httpClient;
