import {createFormData} from '../utils/formDataHelper';
import httpClient from './http.client';

const getAllMessages = () => {
  return httpClient.get('/conversations/');
};

const getMessagesDetail = (messageId) => {
  return httpClient.get(`/conversations/${messageId}/`);
};

const sendMessage = (request) => {
  const formRequest = createFormData(request);
  return httpClient.post(`/messages/send/`, formRequest);
};

const deleteConversation = (userId) => {
  return httpClient.delete(`/conversations/${userId}/`);
};

const muteUser = async (userId) => {
  const respone = await httpClient.post(`user/block/${userId}/`);
  return respone.data;
};

const unmuteUser = async (userId) => {
  const respone = await httpClient.delete(`user/block/${userId}/`);
  return respone.data;
};

export const messagesService = {
  getAllMessages,
  getMessagesDetail,
  sendMessage,
  deleteConversation,
  muteUser,
  unmuteUser,
};
