import React, {useEffect, useRef, useState} from 'react';
import {IonIcon} from '@ionic/react';
import {
  personCircleOutline,
  volumeMuteOutline,
  volumeHighOutline,
  trashOutline,
} from 'ionicons/icons';
import {Modal} from '../modal/Modal';

const BOptionButtons = ({
  user,
  isOpen,
  onClose,
  navigateToUser,
  muteConversation,
  deleteConversation,
}) => {
  const userMuted = user.is_blocked;
  const modalRef = useRef(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) =>
      modalRef.current && !modalRef.current.contains(event.target) && onClose();

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      return;
    }
    document.removeEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      ref={modalRef}
      className='z-40 w-36 h-104 absolute left-56 top-6 border-2 bg-white border-gray-600
		  rounded-lg text-xs'
    >
      <button
        type='button'
        className='w-full h-1/3 flex justify-start items-center border-b border-gray-600 hover:bg-gray-300
		    hover:rounded-t-lg'
        onClick={() => navigateToUser(user.id)}
      >
        <IonIcon icon={personCircleOutline} className='w-6 h-6 mx-2' />
        <div>Profil anzeigen</div>
      </button>
      <button
        className='w-full h-1/3 flex justify-start items-center border-b border-gray-600 hover:bg-gray-300'
        onClick={muteConversation}
      >
        <IonIcon
          icon={userMuted ? volumeMuteOutline : volumeHighOutline}
          className={`w-6 h-6 mx-2 ${userMuted && 'text-gray-500'}`}
        />
        {userMuted && <div className='text-gray-500'>Stumm aufheben</div>}
        {!userMuted && <div>Stumm</div>}
      </button>
      <button
        className='w-full h-1/3 flex justify-start items-center text-red-800 border-b border-gray-600
		    hover:bg-gray-300 hover:rounded-b-lg'
        onClick={() => setDeleteModalOpen(true)}
      >
        <IonIcon icon={trashOutline} className='w-6 h-6 mx-2' />
        <p>Löschen</p>
      </button>

      <Modal
        message='Sind Sie sicher, dass Sie Konversationen löschen möchten?'
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={deleteConversation}
      />
    </div>
  );
};

export default BOptionButtons;
