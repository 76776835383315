import React, {useState} from 'react';
import {IonIcon} from '@ionic/react';
import {useSetRecoilState} from 'recoil';
import {Link} from 'react-router-dom';
import {ellipsisHorizontal} from 'ionicons/icons';
import cutText from '../../utils/cutText';
import BOptionButtons from '../buttons/OptionButtons';
import {receiverMailAtom} from '../../atoms/users.atom';
import {useUserNavigate} from '../hooks/useUserNavigate';
import {useCustomNavigate} from '../hooks/useCustomNavigate';
import {messagesService} from '../../api/messages.service';

import playIcon from '../../assets/player/play.svg';

const BoxContact = (item) => {
  const handleUserClick = useUserNavigate();
  const handleRouteClick = useCustomNavigate();
  const setAtomState = useSetRecoilState(receiverMailAtom);
  const [optionsModal, setOptionsModal] = useState(false);

  const el = item.item;
  const userUnlocked = el.unlocked === true;
  const userMuted = el.is_blocked === true;

  const handleMuteConversation = async () => {
    if (!userMuted) {
      await messagesService.muteUser(el.id);
    }

    if (userMuted) {
      await messagesService.unmuteUser(el.id);
    }

    setOptionsModal(false);
    window.location.reload();
  };

  const handleDeleteConversation = () => messagesService.deleteConversation(el.id);

  return (
    <div
      className='box relative justify-between border-b border-gray-300 w-full p-4'
      onClick={() => setAtomState(el.user)}
    >
      <div>
        <div className='flex'>
          {userUnlocked && (
            <div className='flex justify-center items-center gap-x-2'>
              <button
                type='button'
                className='flex justify-center items-center text-green-accent'
                onClick={() => handleRouteClick(`/nachrichten/${el.id}`)}
              >
                {el.sender_email}
              </button>
              <button type='button' className='flex justify-center items-center'>
                <IonIcon
                  slot='icon-only'
                  icon={ellipsisHorizontal}
                  className='flex mt-1 ml-2'
                  onClick={() => setOptionsModal(!optionsModal)}
                />
              </button>
            </div>
          )}
          {!userUnlocked && <div>ID: {el.id}</div>}
        </div>
        {userUnlocked && (
          <Link to={`/nachrichten/${el.id}`} className='flex items-center'>
            <div className='message'>{checkMusicFile(cutText(el.content, 110))}</div>
          </Link>
        )}
        {!userUnlocked && <div className='message'>{checkMusicFile(cutText(el.content, 110))}</div>}
      </div>

      {optionsModal && (
        <BOptionButtons
          user={item.item}
          isOpen={optionsModal}
          onClose={() => setOptionsModal(false)}
          navigateToUser={handleUserClick}
          muteConversation={handleMuteConversation}
          deleteConversation={handleDeleteConversation}
        />
      )}
    </div>
  );
};

export default BoxContact;

const checkMusicFile = (message) => {
  const prefix = 'https://hearme-app.com/api/uploads/audio/';
  const suffix = '.mp3';

  if (message?.startsWith(prefix) && message?.endsWith(suffix)) {
    return (
      <div className='flex justify-start items-center gap-x-2'>
        <img src={playIcon} className='w-6' />
        Audiodatei
      </div>
    );
  } else {
    return message;
  }
};
