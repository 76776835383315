import {useCallback, useEffect, useState} from 'react';
import BErrorIndicator from '../../components/indicators/ErrorIndicator';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';
import BTable from '../../components/tables/Table';
import BNoDataIndicator from '../../components/indicators/NoDataIndicator';
import {ORDER_WAY} from '../../components/constants/componentsConstants';
import {useParams} from 'react-router';
import getDefaultVisibleFields from '../../providers/entitiesDefaultFieldsProvider';

function BResourcePage({aboveTableComponent, maxNmbOfChars, fieldsVisibleAtBeginning, songs}) {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [resources, setResources] = useState([]);
  const [isLoading] = useState(false);
  const [error] = useState(false);
  const [ordering, setOrdering] = useState([]);
  const [availableWidth, setAvailableWidth] = useState(0);
  const availableOptions = [10, 15, 20, 40];
  const [noData] = useState(false);
  const {entities} = useParams();

  useEffect(() => {
    setResources(songs);

    if (entities === 'users') setIsReadOnly(true);
    else if (entities === 'tracks') setIsReadOnly(true);
    else setIsReadOnly(false); // eslint-disable-next-line
  }, [entities, page, pageSize, ordering]);

  // const formatUrl = () => {
  //   const order = ordering.join();
  //   return `/${entities}?page_size=${pageSize}&page=${page}&ordering=${order}`;
  // };

  // eslint-disable-next-line
  const child = useCallback((node) => {
    if (node !== null) {
      setAvailableWidth(node.getBoundingClientRect().width - 10);
    }
  });

  const addOrdering = (field) => {
    let tempOrdering = [...ordering];
    field = field.replace(/([A-Z])/g, '_$1').toLowerCase();
    let orderState;

    if (tempOrdering.includes(field)) {
      const idx = tempOrdering.indexOf(field);
      tempOrdering[idx] = `-${field}`;
      orderState = ORDER_WAY.decrease;
    } else if (tempOrdering.includes(`-${field}`)) {
      const idx = tempOrdering.indexOf(`-${field}`);
      tempOrdering.splice(idx, 1);
      orderState = ORDER_WAY.default;
    } else {
      tempOrdering.push(field);
      orderState = ORDER_WAY.increase;
    }
    setOrdering(tempOrdering);
    setPage(1);
    return orderState;
  };

  if (isLoading) {
    return <BLoadingIndicator />;
  } else if (error) {
    return <BErrorIndicator />;
  }

  if (noData) {
    return <BNoDataIndicator />;
  }

  return (
    <div ref={child} className='p-6'>
      <BTable
        data={resources}
        header={'Alle lieder'}
        availableOptions={availableOptions}
        setPageSize={setPageSize}
        pageSize={pageSize}
        addOrdering={addOrdering}
        title={entities}
        ordering={ordering}
        availableWidth={availableWidth}
        maxNmbOfChars={maxNmbOfChars}
        aboveTableComponent={aboveTableComponent}
        isReadOnly={isReadOnly}
        fieldsVisibleAtBeginning={getDefaultVisibleFields(entities)}
      />
    </div>
  );
}

export default BResourcePage;
