import {BehaviorSubject} from 'rxjs';
import httpClient from './http.client';

const accessToken = new BehaviorSubject('');
const refreshToken = new BehaviorSubject('');
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const RECOVER_PASSWORD_ENDPOINT_URL = 'reset-password/';
const RESET_PASSWORD_ENDPOINT_URL = 'reset-password/complete/';

const login = async (loginData) => {
  const res = await httpClient.post('partner-login/', loginData);
  setAccessToken(res.data.access);
  setRefreshToken(res.data.refresh);
};

const register = async (registerData) => {
  const response = await httpClient.post('register-partner/', registerData);
  return response;
};

const refreshAccessToken = async () => {
  await setRefreshTokenFromStorage();

  if (!refreshToken.value) logout();

  const data = {refresh: refreshToken.value};

  return httpClient
    .post('/token/refresh/', data)
    .then((res) => {
      setAccessToken(res.data.token);
      setRefreshToken(res.data.refresh);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
      }
    });
};

const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  accessToken.next(token);
};

const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
  refreshToken.next(token);
};
const recoverPassword = (forgetPasswordData) => {
  return httpClient.post(`/${RECOVER_PASSWORD_ENDPOINT_URL}`, forgetPasswordData);
};

const checkResetPasswordValidity = (resetPasswordToken) => {
  return httpClient.get(`/${RESET_PASSWORD_ENDPOINT_URL}${resetPasswordToken}`);
};
const resetPasswordToken = (resetPasswordData, resetPasswordToken) => {
  console.log(resetPasswordToken);
  return httpClient.post(`/${RESET_PASSWORD_ENDPOINT_URL}${resetPasswordToken}`, resetPasswordData);
};

const logout = () => {
  accessToken.next('');
  refreshToken.next('');
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

const setAccessTokenFromLocalStorage = () => {
  const tokenFromLocalStorage = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (tokenFromLocalStorage) {
    setAccessToken(tokenFromLocalStorage);
  }
};

const setRefreshTokenFromStorage = async () => {
  const tokenFromLocalStorage = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (tokenFromLocalStorage) {
    refreshToken.next(tokenFromLocalStorage);
  }
};

export const authService = {
  register,
  login,
  refreshAccessToken,
  logout,
  setAccessTokenFromLocalStorage,
  setRefreshTokenFromStorage,
  recoverPassword,
  checkResetPasswordValidity,
  resetPasswordToken,
  currentToken: accessToken.asObservable(),
  get tokenValue() {
    return accessToken.value;
  },
};
