import httpClient from './http.client';

const getTrack = (id) => {
  return httpClient.get(`/tracks/${id}/`);
};

const getSongs = () => {
  return httpClient.get('/tracks/');
};

export const songServices = {
  getTrack,
  getSongs,
};
