import React, {useEffect, useState} from 'react';
import {genresService} from '../../api/genres.service';
import {languagesService} from '../../api/languages.service';
import LoadingIndicator from '../indicators/LoadingIndicator';
import SearchBar from './SearchBar';

function UsersFilter({
  setPickedGenres,
  pickedGenres,
  setPickedLanguages,
  pickedLanguages,
  setPickedSex,
  setStartAge,
  setEndAge,
  setButtonClicked,
  buttonClicked,
  setUsers,
}) {
  const [genres, setGenres] = useState();
  const [languages, setLanguages] = useState();

  useEffect(() => {
    try {
      languagesService.getAllLanguages().then((res) => {
        setLanguages(res.data);
      });
      genresService.getAllGenres().then((res) => {
        setGenres(res.data);
      });
    } catch (error) {
      if (error) throw error.message;
    }
  }, []);

  const handlePickFilter = (e, filteredCategory, setFilteredCategory) => {
    if (e.target.checked === true) {
      let tempArray = filteredCategory;
      tempArray.push(+e.target.value);
      setFilteredCategory(tempArray);
    } else {
      for (let i = 0; i < filteredCategory.length; i++) {
        if (+e.target.value === filteredCategory[i]) {
          let tempArray = filteredCategory;
          tempArray.splice(i, 1);
          setFilteredCategory(tempArray);
        }
      }
    }
  };

  return (
    <div className='flex justify-center'>
      <div className='border border-green-accent rounded-xl p-2 bg-green-accent'>
        <div className='flex'>
          <div className='border border-green-accent bg-white'>
            <div className='mb-3 bg-green-accent text-white text-center font-bold'>Sprache</div>
            <div className='h-[170px] pr-2 flex flex-col overflow-y-scroll'>
              {languages ? (
                languages.map((language) => (
                  <div key={language.id} className='flex justify-between ml-2'>
                    <label htmlFor={language.id}>{language.name}</label>
                    <input
                      type='checkbox'
                      id={language.id}
                      name='genre'
                      value={language.id}
                      onClick={(e) => handlePickFilter(e, pickedLanguages, setPickedLanguages)}
                    />
                  </div>
                ))
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </div>
          <div className='border border-green-accent bg-white'>
            <div className='mb-3 bg-green-accent text-white text-center font-bold'>Genre</div>
            <div className='h-[170px] pr-2 flex flex-col overflow-y-scroll'>
              {genres ? (
                genres.map((genre) => (
                  <div key={genre.id} className='flex justify-between ml-2'>
                    <label htmlFor={genre.id}>{genre.name}</label>
                    <input
                      type='checkbox'
                      id={genre.id}
                      name='genre'
                      value={genre.id}
                      onClick={(e) => handlePickFilter(e, pickedGenres, setPickedGenres)}
                    />
                  </div>
                ))
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </div>
          <div className='border border-green-accent bg-white'>
            <div className='mb-3 px-3 bg-green-accent text-white text-center font-bold'>Stimme</div>
            <div className='flex flex-col'>
              <label className='flex ml-5 text-lg my-2'>
                <input
                  type='radio'
                  name='plec'
                  value='male'
                  onClick={(e) => setPickedSex(e.target.value)}
                />
                <p className='ml-2 mr-7'>Männlich</p>
              </label>
              <label className='flex ml-5 text-lg my-2'>
                <input
                  type='radio'
                  name='plec'
                  value='female'
                  onClick={(e) => setPickedSex(e.target.value)}
                />
                <p className='ml-2'>Weiblich</p>
              </label>
              <label className='flex ml-5 text-lg my-2'>
                <input
                  type='radio'
                  name='plec'
                  value='X'
                  onClick={(e) => setPickedSex(e.target.value)}
                />
                <p className='ml-2'>Andere</p>
              </label>
            </div>
          </div>
          <div className='border border-green-accent bg-white'>
            <div className='mb-3 bg-green-accent text-white text-center font-bold'>Alter</div>
            <div className='flex mt-16'>
              <p className='flex mr-2 ml-2'>Altersspanne</p>
              von
              <input
                type='number'
                min={0}
                max={99}
                defaultValue={0}
                className='border-2 border-green-accent w-12 mx-2 h-8 text-center bg-transparent'
                onChange={(e) => setStartAge(e.target.value)}
              />
              bis
              <input
                type='number'
                min={1}
                max={100}
                defaultValue={99}
                className='border-2 border-green-accent w-12 mx-2 h-8 text-center bg-transparent'
                onChange={(e) => setEndAge(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='flex justify-around items-center mt-1'>
          <button
            className='bg-white text-green-accent p-2 rounded-md w-1/3 h-10 font-bold hover:bg-gray-300'
            onClick={() => setButtonClicked(buttonClicked + 1)}
          >
            Filtern
          </button>
          <div className='flex justify-center items-center'>
            <SearchBar setUsers={setUsers} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersFilter;
