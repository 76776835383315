import React, {useEffect} from 'react';
import {useState} from 'react';
import {useSetRecoilState} from 'recoil';
import {paymentsService} from '../../api/payments.service';
import {setPromotionAtom} from '../../atoms/promotions.atom';

import activeFirstPlace from '../../assets/promotions/partnerPromotions/promotion1clicked.png';
import activeSecondPlace from '../../assets/promotions/partnerPromotions/promotion2clicked.png';
import activeThirdPlace from '../../assets/promotions/partnerPromotions/promotion3clicked.png';
import inactiveFirstPlace from '../../assets/promotions/partnerPromotions/promotion1unclicked.png';
import inactiveSecondPlace from '../../assets/promotions/partnerPromotions/promotion2unclicked.png';
import inactiveThirdPlace from '../../assets/promotions/partnerPromotions/promotion3unclicked.png';
import './productPlacement.css';

/// Purchase promotion on partner's page
const ProfilePromotion = () => {
  const todayDate = new Date(Date.now()).toLocaleDateString();

  const [clicked, setClicked] = useState();
  const [nextFreeDate, setNextFreeDate] = useState();

  const setPromotionInfo = useSetRecoilState(setPromotionAtom);

  useEffect(() => {
    const fetchData = async () => {
      if (clicked != null) {
        try {
          const paymentsRes = await paymentsService.sendPaymentInitialization({
            product_promotion: clicked,
          });
          const validFromDate = paymentsRes.data.product_promotion.valid_from;
          const formattedDate = new Date(validFromDate).toLocaleDateString();
          setNextFreeDate(formattedDate);
        } catch (error) {
          console.error(error);
          setNextFreeDate(todayDate);
        }
      }
    };

    fetchData();
  }, [clicked]); // eslint-disable-next-line

  const handleClickPromotion = (id) => {
    setClicked(id);
    setPromotionInfo({product_promotion: +id});
  };

  const buttons = [
    {id: 2, alt: 'second', activeSrc: activeSecondPlace, inactiveSrc: inactiveSecondPlace},
    {id: 1, alt: 'first', activeSrc: activeFirstPlace, inactiveSrc: inactiveFirstPlace},
    {id: 3, alt: 'third', activeSrc: activeThirdPlace, inactiveSrc: inactiveThirdPlace},
  ];

  return (
    <div className='w-full h-full flex-col pt-5 px-5'>
      <div className='text-2xl text-white font-bold my-5'>Vorreihung</div>
      <div className='flex justify-center items-end space-x-2 mt-16'>
        {buttons.map((button) => (
          <button key={button.id} onClick={() => handleClickPromotion(button.id)}>
            <img
              alt={button.alt}
              src={clicked === button.id ? button.activeSrc : button.inactiveSrc}
            />
          </button>
        ))}
      </div>
      <div className='mt-5 text-center text-lg text-white font-medium'>
        Verfügbar ab: {nextFreeDate ?? 'Bitte wählen Sie ein Paket aus'}
      </div>
    </div>
  );
};

export default ProfilePromotion;
