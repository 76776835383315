import BForm from '../components/forms/Form';

function FormResetPassword({onSubmit}) {
  const fields = [
    {
      fieldName: 'email',
      placeholder: 'E-Mail',
    },
    {
      fieldName: 'password',
      placeholder: 'Passwort',
      validation: {
        required: true,
        minLength: {
          value: 2,
          message: 'Passwort sollte mindestens 2 Zeichen lang sein',
        },
      },
      type: 'password',
    },
    {
      fieldName: 'passwordConfirm',
      placeholder: 'Passwort Bestätigung',
      validation: {
        required: true,
        minLength: {
          value: 2,
          message: 'Passwort sollte mindestens 2 Zeichen lang sein',
        },
      },
      type: 'password',
    },
  ];

  return <BForm fields={fields} btnText='Zurücksetzen' submit={onSubmit} scroll='' />;
}

export default FormResetPassword;
