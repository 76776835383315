import React from 'react';
import Table from 'rc-table';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';
import {
  musicIcon,
  tableCss,
  tableRowCss,
  userButton,
  userId,
} from '../../components/constants/componentsConstants';

const ContactsTable = ({contacts, playButton, contactUnlockButtons}) => {
  const handleUserClick = useUserNavigate();

  const contactsColumns = [
    {
      title: musicIcon,
      dataIndex: 'player',
      key: 'player',
      width: 100,
      render: (record) => playButton(record),
    },
    {
      title: userId(),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (record) => userButton(handleUserClick, record),
    },
    {
      title: 'Anzahl Tracks',
      dataIndex: 'trackCount',
      key: 'trackCount',
      width: 100,
    },
    {
      title: 'Bewertung',
      dataIndex: 'rating',
      key: 'rating',
      width: 100,
    },
    {
      title: 'Beliebtheit',
      dataIndex: 'tracksRating',
      key: 'tracksRating',
      width: 100,
    },
    {
      title: 'Registrierdatum',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: 100,
    },
    {
      title: 'Profil freischalten',
      dataIndex: 'unlockProfile',
      key: 'unlockProfile',
      width: 100,
      render: (record) => contactUnlockButtons(record, 'Profilfreischaltung'),
    },
  ];

  return (
    <>
      {contacts.length !== 0 && (
        <Table
          rowKey={(record) => record.id.userId}
          scroll={{x: false, y: contacts.length > window.innerHeight * 0.85 ? '85vh' : undefined}}
          className={tableCss}
          rowClassName={(record, i) => tableRowCss(i)}
          columns={contactsColumns}
          data={contacts
            .sort((a, b) => a.user.id - b.user.id)
            .map((el, index) => {
              const user = el.user;
              const artist = user?.artist;

              return {
                player: {user: user},
                id: {userId: user.id},
                trackCount: artist?.tracks_count ? artist?.tracks_count : 0,
                rating: artist?.rating ? artist?.rating : 0,
                tracksRating: user?.artist?.tracks_rating ? artist?.tracks_rating : 0,
                registrationDate: el?.user?.date_joined?.substring(0, 10),
                unlockProfile: {userId: el.user?.id, index: index},
              };
            })}
        />
      )}
    </>
  );
};

export default ContactsTable;
