import React, {Fragment} from 'react';
import {useRecoilState} from 'recoil';
import {currentTrackIdAtom, playingAtom} from '../../atoms/tracks.atom';

const UserTrackList = ({tracks}) => {
  const [playing, setPlaying] = useRecoilState(playingAtom);
  const [currentTrackId, setCurrentTrackId] = useRecoilState(currentTrackIdAtom);

  const handleTrackClick = (track) => {
    if (track.id === currentTrackId) {
      setPlaying((prevPlaying) => !prevPlaying);
      return;
    }
    setCurrentTrackId(track.id);
    setPlaying(true);
  };

  return (
    <div className='relative mx-10 my-5 flex flex-col border-8 border-white rounded-xl'>
      <table className='divide-y divide-gray-300 w-full'>
        <thead className='bg-gray-50'>
          <tr className='px-6 py-2 text-center text-gray-500'>
            <th></th>
            <th>ID</th>
            <th>Name</th>
            <th>Album</th>
            <th>Genre</th>
            <th>Sprache</th>
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-300'>
          {tracks.map((track) => (
            <Fragment key={track.id}>
              <tr className='text-center'>
                <td className='px-6 py-4 relative'>
                  <img
                    alt={track.name}
                    src={track.image}
                    className={`w-32 rounded-full hover:cursor-pointer ${
                      track.id === currentTrackId &&
                      playing &&
                      `animate-[spin_10s_linear_infinite] duration-1000 p-2 rounded-full
                      bg-gradient-conic-[#0ea5e9_20deg,transparent_180deg]`
                    }`}
                    onClick={() => handleTrackClick(track)}
                  />
                </td>
                <td className='px-6 py-4 text-sm text-gray-500'>{track.id}</td>
                <td className='px-6 py-4'>
                  <div className='text-sm text-gray-900'>{track.name}</div>
                </td>
                <td className='px-6 py-4'>
                  <div className='text-sm text-gray-900'>{track.albumName}</div>
                </td>
                <td className='px-6 py-4'>
                  <div className='text-sm text-gray-900'>{track.genre}</div>
                </td>
                <td className='px-6 py-4'>
                  <div className='text-sm text-gray-900'>{track.language}</div>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTrackList;
