import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types';

export default function BDateFormInput({placeholder, fieldName, control, dateFormat}) {
  const [selectValue, setSelectValue] = useState({});
  const isDateFormatWithoutHours = dateFormat === 'YYYY-MM-DD';

  const handleInputChange = (e) => {
    if (e == null) {
      return null;
    }

    let date = new Date(e.target.value).toISOString();
    if (isDateFormatWithoutHours) {
      date = new Date(e.target.value).toISOString().slice(0, 10);
    }

    setSelectValue(e.target.value);
    return date;
  };
  const getPreloadedDataFromControl = () => {
    return control.defaultValuesRef && control.defaultValuesRef.current[`${fieldName}`]
      ? control.defaultValuesRef?.current[`${fieldName}`].slice(0, 19)
      : '';
  };

  useEffect(() => {
    setSelectValue(getPreloadedDataFromControl()); // eslint-disable-next-line
  }, []);

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({field: {onChange}}) => (
        <input
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          type={'date'}
          placeholder={placeholder}
          onChange={(e) => onChange(handleInputChange(e))}
          value={selectValue}
        />
      )}
      rules={{required: true}}
    />
  );
}

BDateFormInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};
