import React from "react";

export default function BCheckboxInput({
  fieldName,
  placeholder,
  register,
  validation,
}) {
  return (
    <label className="inline-flex items-center text-l mt-3">
      <input
        className="form-checkbox h-5 w-5 text-gray-600"
        type="checkbox"
        placeholder={placeholder}
        {...register(fieldName, { ...validation })}
      />
      <span className="ml-2 text-gray-700">{placeholder}</span>
    </label>
  );
}
