import React, {useState} from 'react';
import {authService} from '../api/auth.service';
import FormRecoverPassword from '../forms/FormRecoverPassword';

const PageRecoverPassword = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const tryRecoverPassword = (recoverPasswordData) => {
    setSuccess(false);
    setError('');
    authService
      .recoverPassword(recoverPasswordData)
      .then(() => setSuccess(true))
      .catch((err) => {
        const errorData = err.response.data;
        if (errorData.email) {
          setError(errorData.email[0]);
          return;
        }
        setError('Konto existiert nicht');
      });
  };

  return (
    <>
      <div className='h-auto min-h-screen bg-main m-0 w-full flex items-center justify-center flex-col'>
        <div className='text-white font-sans justify-content'>PASSWORT WIEDERHERSTELLEN</div>

        <div className='p-5 h-full flex flex-col justify-center items-center'>
          <div className='w-full flex flex-col'>
            <p className='text-white text-center text-sm'>Bitte gib deine E-Mail ein</p>
            <div className='flex justify-center'>{<FormRecoverPassword onSubmit={tryRecoverPassword} />}</div>
          </div>
          {error && <div className='text-xl text-red-500 text-center my-10'>{error}</div>}
          {success && (
            <div className='text-green-300 text-md text-center my-10'>
              Eine E-Mail mit einem Link zum Wiederherstellen Ihres Passworts wurde gesendet
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PageRecoverPassword;
