export const entitiesNames = {
  KONTAKTE: 'kontakte',
  SEARCH: 'suchen',
  CONVERSATIONS: 'nachrichten',
  SONGPREVIEW: 'lieder-vorhoren',
  PROFILEDIT: 'profil-bearbeiten',
  NEWS: 'news',
  COMPANYDATA: 'impressum',
  RANGLISTEN: 'ranglisten',
  // STATS: 'statistiken', // TODO
  SETTINGS: 'einstellungen',
  PAYMENTS: 'kaufoptionen',
};

export const entitiesList = Object.keys(entitiesNames).map((k) => entitiesNames[k]);
