import { entitiesNames } from "./entitiesProvider";

export default function getDefaultVisibleFields(entities) {
  switch (entities) {
    case entitiesNames.ALBUMS:
      return [
        "id",
        "name",
        "artist",
        "genre",
        "file"
      ];
    case entitiesNames.ARTICLES:
      return [
        "id",
        "title",
        "createdDate",
        "photo",
        "text"
      ];
    case entitiesNames.COUNTRIES:
      return ['name', 'id'];
    case entitiesNames.USERS:
      return [
        "id",
        "email",
        "alias",
        "description",
        "userName",
        "photo",
      ];
    case entitiesNames.LABELS:
      return ['name', 'id'];
    case entitiesNames.PRIZES:
      return ['name', 'id'];
    case entitiesNames.ARTISTS:
      return [
        "id",
        "alias",
        "description",
        "fullName",
        "averageRate"
      ];
    case entitiesNames.GENRES:
      return ['name', 'id'];
    case entitiesNames.TRACKS:
      return[
      "id",
      "name",
      "genre",
      "album",
      "label",
      "description"
    ];
    case entitiesNames.SONGPREVIEW:
      return [
        "id",
        "name",
        "genre",
        "artist",
        "image",
        "file",
      ]
    default:
      break;
  }
}
