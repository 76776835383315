import {useEffect, useState} from 'react';
import {genresService} from '../api/genres.service';
import {languagesService} from '../api/languages.service';
import BForm from '../components/forms/Form';

function RegisterForm({onSubmit}) {
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    genresService.getAllGenres().then((res) => {
      let data = res.data.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setGenres(data);
    });
    languagesService.getAllLanguages().then((res) => {
      let data = res.data.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setLanguages(data);
    });
    return () => {
      setGenres([]);
      setLanguages([]);
    };
  }, []);

  const genders = [
    {
      label: 'Frau',
      value: 0,
    },
    {
      label: 'Herr',
      value: 1,
    },
  ];

  const fields = [
    {
      fieldName: 'name',
      placeholder: 'Name',
    },
    {
      fieldName: 'phone',
      placeholder: 'Telefonnummer',
    },
    {
      fieldName: 'address',
      placeholder: 'Anschrift',
    },
    {
      fieldName: 'company',
      placeholder: 'Markenname',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'website',
      placeholder: 'Webseite',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'vat_number',
      placeholder: 'UID Nummer',
    },
    {
      fieldName: 'email',
      placeholder: 'E-mail',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'sex',
      placeholder: 'Anrede',
      type: 'select',
      options: [...genders],
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'genres',
      placeholder: 'Genres',
      type: 'select',
      isMulti: true,
      options: [...genres],
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'languages',
      placeholder: 'Sprachen',
      type: 'select',
      isMulti: true,
      options: [...languages],
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'password',
      placeholder: 'Passwort',
      validation: {
        required: true,
        minLength: {
          value: 2,
          message: 'Passwort sollte mindestens 2 Zeichen lang sein',
        },
      },
      type: 'password',
    },
    {
      fieldName: 'passwordConfirm',
      placeholder: 'Passwort Bestätigung',
      validation: {
        required: true,
        minLength: {
          value: 2,
          message: 'Passwort sollte mindestens 2 Zeichen lang sein',
        },
      },
      type: 'password',
    },
  ];

  return <BForm fields={fields} btnText='Registrierung' submit={onSubmit} scroll='' />;
}

export default RegisterForm;
