import PropTypes from 'prop-types';
import {useState} from 'react';
import {AiOutlineEye} from 'react-icons/ai';

export default function BPasswordField({fieldName, placeholder, register, validation, type = 'password', step = '1'}) {
  const [isPasswordToggled, setIsPasswordToggled] = useState(false);

  return (
    <div className='flex'>
      <input
        className='shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
        type={isPasswordToggled ? 'text' : type}
        placeholder={placeholder}
        step={step}
        {...register(fieldName, {...validation})}
      />
      <div onMouseDown={() => setIsPasswordToggled(true)} onMouseUp={() => setIsPasswordToggled(false)}>
        <AiOutlineEye
          className={`${isPasswordToggled ? 'text-green-accent' : 'text-white'} absolute left-[93%] h-6 w-9 mt-1`}
        />
      </div>
    </div>
  );
}

BPasswordField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  type: PropTypes.string,
  register: PropTypes.func.isRequired,
  iconClick: PropTypes.func,
};
