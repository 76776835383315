import musicNoteIcon from '../../assets/music_note_icon.svg';

export const ORDER_WAY = {
  increase: 'increase',
  decrease: 'decrease',
  default: 'default',
};

export const tableCss = 'bg-green-accent text-left text-lg text-white';
export const tableRowCss = (index) =>
  `h-16 text-left text-mirage bg-white ${index % 2 === 0 && 'bg-gray-100'}`;

export const musicIcon = (
  <button className='flex justify-start items-center'>
    <img alt='music_note_icon' src={musicNoteIcon} className='w-14 pl-2' />
  </button>
);

export const userId = (isUnlockedTab) => (
  <div className={`flex justify-start items-center ${isUnlockedTab && 'h-[48px] ml-5'}`}>
    User ID
  </div>
);

export const userButton = (handleUserClick, record, customCss) => (
  <button
    className={`text-mirage hover:cursor-pointer hover:text-green-accent hover:pb-1' ${
      customCss && customCss
    }`}
    onClick={() => handleUserClick(record.userId)}
  >
    {record.userId}
  </button>
);
