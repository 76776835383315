import React, {useEffect, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {IonIcon} from '@ionic/react';
import {
  currentTrackIdAtom,
  playingAtom,
  playerVisibilityAtom,
  tracksListAtom,
} from '../../atoms/tracks.atom';
import {songServices} from '../../api/song.service';
import downloadIcon from '../../assets/player/download.svg';
import loopIcon from '../../assets/player/loop.svg';
import loopOffIcon from '../../assets/player/loop_off.svg';
import playIcon from '../../assets/player/play.svg';
import pauseIcon from '../../assets/player/pause.svg';
import forwardIcon from '../../assets/player/play_forward.svg';
import skipForwardIcon from '../../assets/player/play_skip_forward.svg';
import backIcon from '../../assets/player/play_back.svg';
import skipBackIcon from '../../assets/player/play_skip_back.svg';
import volumeMuteIcon from '../../assets/player/volume_mute.svg';
import volumeHighIcon from '../../assets/player/volume_high.svg';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';

const Player = () => {
  const playerRef = useRef(null);
  const handleUserClick = useUserNavigate();
  const [index, setIndex] = useState(0);
  const tracks = useRecoilValue(tracksListAtom);
  const [track, setTrack] = useState(tracks[0]);
  const playerVisible = useRecoilValue(playerVisibilityAtom);
  const [playing, setPlaying] = useRecoilState(playingAtom);
  const [currentTrackId, setCurrentTrackId] = useRecoilState(currentTrackIdAtom);

  useEffect(() => {
    if (tracks.length > 0) {
      setTrack(tracks[index]);
      setCurrentTrackId(tracks[index]?.id);
    }
  }, [tracks, index, setCurrentTrackId]);

  useEffect(() => {
    const newTrack = tracks.find((t) => t.id === currentTrackId);
    if (newTrack) {
      setTrack(newTrack);
    }
  }, [currentTrackId, tracks]);

  useEffect(() => {
    if (playerRef.current && playerRef.current.audio.current) {
      if (playing) {
        playerRef.current.audio.current.play();
      } else {
        playerRef.current.audio.current.pause();
      }
    }
  }, [playing]);

  useEffect(() => {
    const fetchTrack = async () => {
      if (currentTrackId) {
        await songServices.getTrack(currentTrackId);
      }
    };
    fetchTrack();
  }, [currentTrackId]);

  const handlePlayClick = () => {
    setPlaying(true);
  };

  const handlePauseClick = () => {
    setPlaying(false);
  };

  const handleClickNext = () => {
    setIndex((trackIndex) => (trackIndex < tracks.length - 1 ? trackIndex + 1 : 0));
  };
  const handleClickPrevious = () => {
    setIndex((trackIndex) => (trackIndex > 0 ? trackIndex - 1 : tracks.length - 1));
  };
  const handleEnd = () => {
    setIndex((trackIndex) => (trackIndex < tracks.length - 1 ? trackIndex + 1 : 0));
  };

  const downloadSong = () => {
    const link = document.createElement('a');
    link.href = track.file;
    link.download = track.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!playerVisible || tracks.length <= 0) return null;

  return (
    <AudioPlayer
      footer={true}
      ref={playerRef}
      showSkipControls={true}
      showJumpControls={true}
      showDownloadProgress={true}
      progressJumpStep={2000}
      volume={0.3}
      src={track?.file}
      onEnded={handleEnd}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrevious}
      onPause={handlePauseClick}
      onPlay={handlePlayClick}
      customIcons={{
        play: <img src={playIcon} />,
        pause: <img src={pauseIcon} />,
        rewind: <img src={backIcon} />,
        forward: <img src={forwardIcon} />,
        previous: <img src={skipBackIcon} />,
        next: <img src={skipForwardIcon} />,
        loop: <img src={loopIcon} />,
        loopOff: <img src={loopOffIcon} />,
        volume: <img src={volumeHighIcon} />,
        volumeMute: <img src={volumeMuteIcon} />,
      }}
      customAdditionalControls={[]}
      customControlsSection={[
        <>
          {track != undefined && tracks.length > 0 && (
            <>
              <button type='button' onClick={downloadSong}>
                <IonIcon src={downloadIcon} className='text-3xl' />
              </button>
              <div className='w-[20%] flex justify-start items-center'>
                <img alt={track.name} src={track.image} className='w-16 rounded-xl mx-5' />
                <div className='flex flex-col text-mirage gap-y-2'>
                  <div className='font-semibold'>{track.name}</div>
                  <div>{track.albumName}</div>
                  <div
                    className='hover:cursor-pointer hover:text-green-accent hover:underline'
                    onClick={() => handleUserClick(track.artist)}
                  >
                    {track.artist}
                  </div>
                </div>
              </div>
              <div
                className={`sm:invisible md:invisible lg:visible xl:visible   px-3 p-1 w-[15%] mr-[5%] flex flex-col items-start text-white space-y-1.5 bg-mirage rounded-xl ${
                  tracks.length > 3 && 'overflow-y-auto max-h-16 custom-scrollbar'
                }`}
              >
                {tracks.map((song, index) => (
                  <div key={song.id} className='flex space-x-3'>
                    <div className='font-bold'>{index + 1}.</div>
                    <div
                      className={
                        playing &&
                        song.id === track.id &&
                        'font-semibold text-green-accent animate-pulse animate-infinite animate-duration-[1200ms] animate-delay-0'
                      }
                    >
                      {song.name}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>,
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.VOLUME_CONTROLS,
      ]}
    />
  );
};

export default Player;
