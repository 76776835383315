import React, {useEffect, useState} from 'react';
import {usersService} from '../../api/users.service';

import './searchBar.css';

const SearchBar = ({setUsers}) => {
  const [id, setId] = useState();

  useEffect(() => {
    usersService.getUsersById(id).then((res) => {
      setUsers(res.data.users);
    });
  }, [id]);

  return (
    <div className='flex justify-center items-center'>
      <div className='flex flex-col border rounded-xl p-3 bg-green-accent border-green-accent text-center font-semibold text-white'>
        Suche nach ID:
        <input
          type='number'
          className='w-[300px] text-black-accent rounded-xl text-center'
          onChange={(e) => setId(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchBar;
