import httpClient from './http.client';

const getAllPartnerContacts = () => {
  return httpClient.get('/backoffice/partner-contacts');
};

const getAcceptedContacts = () => {
  return httpClient.get(`/backoffice/partner-contacts/approved/`);
};

const getUnlocked = () => {
  return httpClient.get(`/unlocked-profiles/`);
};

const getContracted = () => {
  return httpClient.get(`/backoffice/partner-contracts/`);
};

const acceptContact = (id) => {
  return httpClient.post(`/backoffice/partner-contacts/approve/${id}/`);
};

const acceptContract = (id) => {
  return httpClient.post(`/backoffice/partner-contracts/approve/${id}/`);
};

const getCompanyData = (id) => {
  return httpClient.get(`/partners/${id}/extended/`);
};

const postCompanyData = (id, data) => {
  return httpClient.patch(`/partners/${id}/extended/`, data);
};

const postNews = (data) => {
  return httpClient.post(`/partners/news/`, {news: data});
};

const editNews = (id, data) => {
  return httpClient.patch(`/partners/news/${id}/`, {news: data});
};

const deleteNews = (id) => {
  return httpClient.delete(`partners/news/${id}/`);
};

const promotedPartners = () => {
  return httpClient.get('/promoted-partners/');
};

export const partnerService = {
  getAllPartnerContacts,
  getAcceptedContacts,
  getUnlocked,
  getContracted,
  acceptContact,
  acceptContract,
  getCompanyData,
  postCompanyData,
  postNews,
  editNews,
  deleteNews,
  promotedPartners,
};
