import React from 'react';
import Table from 'rc-table';
import {
  musicIcon,
  tableCss,
  tableRowCss,
  userButton,
  userId,
} from '../../components/constants/componentsConstants';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';

const TopArtistsTable = ({artists, playButton}) => {
  const handleUserClick = useUserNavigate();

  const artistsColumns = [
    {
      title: musicIcon,
      dataIndex: 'player',
      key: 'player',
      width: 100,
      render: (record) => playButton(record),
    },
    {
      title: userId(),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (record) => userButton(handleUserClick, record),
    },
    {
      title: 'Punkte',
      dataIndex: 'points',
      key: 'points',
      width: 100,
    },
  ];

  return (
    <>
      {artists?.length !== 0 && (
        <div className='flex flex-col w-full'>
          <div className='w-1/3 pl-3 py-2 bg-green-accent rounded-tr-xl text-white font-semibold'>
            Top Users
          </div>
          <Table
            rowKey={(record) => record.id.userId}
            scroll={{x: false, y: artists?.length > window.innerHeight * 0.85 ? '85vh' : undefined}}
            className={tableCss}
            rowClassName={(record, i) => tableRowCss(i)}
            columns={artistsColumns}
            data={artists
              ?.sort((a, b) => a.id - b.id)
              ?.map((user) => ({
                player: {user: user},
                id: {userId: user?.user.id},
                points: user?.rating,
              }))}
          />
        </div>
      )}
    </>
  );
};

export default TopArtistsTable;
