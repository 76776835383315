import React from 'react';
import Table from 'rc-table';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';
import {
  tableCss,
  tableRowCss,
  userButton,
  userId,
} from '../../components/constants/componentsConstants';

const UnlockedTable = ({unlocked, contactUnlockButtons}) => {
  const handleUserClick = useUserNavigate();

  const unlockedColumns = [
    {
      title: userId(true),
      dataIndex: 'id',
      key: 'id',
      width: 125,
      render: (record) => userButton(handleUserClick, record, 'ml-5'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 175,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Geburtsdatum',
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: 150,
    },
    {
      title: 'Bewertung',
      dataIndex: 'rating',
      key: 'rating',
      width: 125,
    },
    {
      title: 'Anrede',
      dataIndex: 'sex',
      key: 'sex',
      width: 100,
    },
    {
      title: 'Genres',
      dataIndex: 'genres',
      key: 'genres',
      width: 200,
      render: (record) =>
        record.genres.map((genre, idx) => (idx === 0 ? genre.name : ', ' + genre.name)),
    },
    {
      title: 'Sprachen',
      dataIndex: 'languages',
      key: 'languages',
      width: 150,
      render: (record) =>
        record.languages.map((language, idx) => (idx === 0 ? language.name : ', ' + language.name)),
    },
    {
      title: 'Vertragsliste',
      dataIndex: 'contractList',
      key: 'contractList',
      width: 150,
      render: (record) => contactUnlockButtons(record, 'Unter Vertrag'),
    },
  ];

  return (
    <>
      {unlocked.length !== 0 && (
        <Table
          rowKey={(record) => record.id.userId}
          scroll={{x: false, y: unlocked.length > window.innerHeight * 0.85 ? '85vh' : undefined}}
          className={tableCss}
          rowClassName={(record, i) => tableRowCss(i)}
          columns={unlockedColumns}
          data={unlocked
            .sort((a, b) => a.id - b.id)
            .map((user, index) => ({
              id: {userId: user?.id},
              name: user?.name,
              email: user?.email,
              birthDate: user?.birth_date,
              rating: user?.artist?.rating ? user?.artist?.rating : 0,
              sex: user?.sex === 'male' ? 'Herr' : 'Frau',
              genres: {genres: user?.genre},
              languages: {languages: user?.language},
              contractList: {userId: user?.id, index: index},
            }))}
        />
      )}
    </>
  );
};

export default UnlockedTable;
