import React, {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default function BFormSelectInput({placeholder, fieldName, control, options, isMulti = false, defaultValue}) {
  const [selectValue, setSelectValue] = useState();
  const inputRef = useRef();
  useEffect(() => {
    const val = getPreloadedDataFromControl();
    setSelectValue(val); // eslint-disable-next-line
  }, [options]);

  const handleInputChange = (e) => {
    if (e == null) {
      return null;
    }

    setSelectValue(e);

    if (e?.length > 0) return e.map((v) => v.value);

    return e.value;
  };
  const getPreloadedDataFromControl = () => {
    if (!options) return;

    return control._defaultValues && control._defaultValues[`${fieldName}`]
      ? options.find((o) => o.label === control._defaultValues[`${fieldName}`])
      : undefined;
  };

  const switchName = (fieldName) => {
    if (fieldName === 'sex') {
      return 'Anrede';
    }
    if (fieldName === 'genres') {
      return 'Genre';
    }
    if (fieldName === 'languages') {
      return 'Sprache';
    }
  };

  const colourStyles = {
    control: (styles) => ({...styles, backgroundColor: 'white'}),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: 'white',
        color: 'hsl(177, 66%, 41%)',
      };
    },
  };

  return (
    <div>
      <Controller
        control={control}
        name={fieldName}
        render={({field: {onChange}}) => (
          <Select
            defaultValue={defaultValue}
            styles={colourStyles}
            isMulti={isMulti}
            onChange={(e) => onChange(handleInputChange(e))}
            placeholder={placeholder}
            options={options}
            ref={inputRef}
            value={selectValue}
            noOptionsMessage={() => `Kein ${switchName(fieldName)} marschiert zum Suchbegriff.`}
          />
        )}
      />
    </div>
  );
}

BFormSelectInput.propTypes = {
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.isRequired,
    })
  ),
};
