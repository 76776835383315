import React from 'react';
import Table from 'rc-table';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';
import {
  musicIcon,
  tableCss,
  tableRowCss,
  userButton,
  userId,
} from '../../components/constants/componentsConstants';

const ContractedTable = ({contracted, playButton}) => {
  const handleUserClick = useUserNavigate();

  const contractsColumns = [
    {
      title: musicIcon,
      dataIndex: 'player',
      key: 'player',
      width: 100,
      render: (record) => playButton(record),
    },
    {
      title: userId(),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (record) => userButton(handleUserClick, record),
    },
    {
      title: 'Anzahl Tracks',
      dataIndex: 'trackCount',
      key: 'trackCount',
      width: 100,
    },
    {
      title: 'Bewertung',
      dataIndex: 'rating',
      key: 'rating',
      width: 100,
    },
    {
      title: 'Beliebtheit',
      dataIndex: 'popularity',
      key: 'popularity',
      width: 100,
    },
    {
      title: 'Registrierdatum',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      width: 100,
    },
  ];

  return (
    <>
      {contracted.length !== 0 && (
        <Table
          rowKey={(record) => record.id.userId}
          scroll={{x: false, y: contracted.length > window.innerHeight * 0.85 ? '85vh' : undefined}}
          className={tableCss}
          rowClassName={(record, i) => tableRowCss(i)}
          columns={contractsColumns}
          data={contracted
            .sort((a, b) => a.id - b.id)
            .map((user) => ({
              player: {user: user},
              id: {userId: user.id},
              trackCount: user.artist?.tracks_count ? user.artist?.tracks_count : 0,
              rating: user.artist?.rating ? user.artist?.rating : 0,
              popularity: user.visitors,
              registrationDate: user.register_date.substring(0, 10),
            }))}
        />
      )}
    </>
  );
};

export default ContractedTable;
