import React from 'react';
import {Link, useParams} from 'react-router-dom';

const SideBarLink = ({optionName, route}) => {
  const entities = useParams();
  return (
    <li
      className={
        entities['*'].toLowerCase().replace('-', ' ') === optionName.toLowerCase()
          ? 'py-2 bg-green-accent hover:bg-green-accent  flex transition duration-200 ease-in-out rounded-md w-full mt-2 cursor-pointer'
          : 'py-2 hover:bg-green-accent  flex transition duration-200 ease-in-out rounded-md w-full mt-2 cursor-pointer'
      }
    >
      <Link className='text-white w-full' to={route} data-toggle='collapse' aria-expanded='false'>
        {optionName}
      </Link>
    </li>
  );
};

export default SideBarLink;
