import React, {useEffect, useState} from 'react';
import {partnerService} from '../api/partners.service';
import {crudService} from '../api/crud.service';
import {BNavigateBox} from '../components/navigateBox/NavigateBox';
import CompanyDataForm from '../forms/companyData.form';

const BCompanyDataPage = () => {
  const [preloadedData, setPreloadedData] = useState();
  const [partnerId, setPartnerId] = useState();
  const [response, setResponse] = useState();
  const [errorResponse, setErrorResponse] = useState();
  useEffect(() => {
    setErrorResponse(null);
    crudService.getAll('/current-user').then((res) => {
      setPartnerId(res.data.id);
      partnerService.getCompanyData(res.data.id).then((res) => {
        setPreloadedData(res.data);
      });
    });
  }, []);

  const handleSubmit = (data) => {
    data.partner === null && delete data.partner;
    partnerService
      .postCompanyData(partnerId, data)
      .then((res) => {
        setErrorResponse(null);
        setResponse('Änderung gespeichert!');
      })
      .catch(
        (err) => (// eslint-disable-next-line
          setResponse(null),
          err.response.data.company_number &&
            setErrorResponse('Error! Das Feld Firmenbuchnummer muss genau 7 Zeichen lang sein!'),
          err.response.data.uid && setErrorResponse('Error! Das Feld UID-Nummer muss genau 11 Zeichen lang sein!'),
          err.response.data.email && setErrorResponse('Error! Bitte geben Sie eine gültige E-Mail ein!')
        )
      );
  };

  return (
    <>
      <BNavigateBox title={'Impressum'} />
      <div className='bg-white  rounded shadow-md flex flex-col min-h-[50%] h-auto border-gray-400 py-2 px-3 mt-10 m-6 pb-20'>
        {preloadedData && (
          <CompanyDataForm
            btnText={'Senden'}
            onSubmit={handleSubmit}
            preloadedData={preloadedData}
            success={response}
          />
        )}
        {errorResponse && <p className='text-red-500 font-bold text-center'>{errorResponse}</p>}
      </div>
    </>
  );
};

export default BCompanyDataPage;
