import React from 'react';
import BFormSelectInput from '../inputs/SelectInput';
import BDateFormInput from '../inputs/DateFormInput';
import BCheckboxInput from '../inputs/CheckboxInput';
import BFileInput from '../inputs/FileInput';
import BTextareaField from './TextareaField';
import BField from './Field';
import BPasswordField from './PasswordField';

export default function BFieldWrapper({field, value, errors, control, register, fieldColor}) {
  const {
    dateFormat,
    fieldName,
    btnText,
    placeholder,
    options,
    isMulti,
    defaultValue,
    cols,
    validation,
    type = 'text',
    step = 1,
    iconClick,
  } = field;

  const formatError = (error) => {
    let errorMessage = error.message;
    if (error.type === 'required') {
      errorMessage = 'Dieses Feld ist erforderlich';
    }
    return <p className='text-center text-red-500 mt-1.5 text-xs'>{errorMessage}</p>;
  };
  let fieldToReturn;
  if (type === 'select') {
    fieldToReturn = (
      <BFormSelectInput
        fieldName={fieldName}
        placeholder={placeholder}
        control={control}
        options={options}
        isMulti={isMulti}
        defaultValue={defaultValue}
      />
    );
  } else if (type === 'date') {
    fieldToReturn = (
      <BDateFormInput fieldName={fieldName} placeholder={placeholder} control={control} dateFormat={dateFormat} />
    );
  } else if (type === 'checkbox') {
    fieldToReturn = (
      <BCheckboxInput fieldName={fieldName} placeholder={placeholder} register={register} validation={validation} />
    );
  } else if (type === 'file') {
    fieldToReturn = (
      <BFileInput fieldName={fieldName} placeholder={placeholder} value={value} control={control} btnText={btnText} />
    );
  } else if (type === 'textarea') {
    fieldToReturn = (
      <BTextareaField
        fieldName={fieldName}
        placeholder={placeholder}
        register={register}
        validation={validation}
        cols={cols}
        rows={5}
        type={type}
        step={step}
      />
    );
  } else if (type === 'password') {
    fieldToReturn = (
      <BPasswordField
        fieldName={fieldName}
        placeholder={placeholder}
        register={register}
        validation={validation}
        type={type}
        step={step}
        iconClick={iconClick}
      />
    );
  } else {
    fieldToReturn = (
      <BField
        fieldName={fieldName}
        placeholder={placeholder}
        register={register}
        validation={validation}
        type={type}
        step={step}
      />
    );
  }

  return (
    <div>
      <div className={`capitalize pt-3  ${fieldColor ? fieldColor : 'text-white'} font-bold`}>{placeholder}</div>
      <div key={fieldName} className='h-1/3'>
        {fieldToReturn}
        {errors && fieldName in errors && formatError(errors[fieldName])}
      </div>
    </div>
  );
}
