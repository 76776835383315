import {IoClose, IoCheckmark} from 'react-icons/io5';

const emailRegex = /^[^\s@]+@[^\s@]+$/;
const isoDateRegex = /(\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(.+?\d+)?)/;

const formatField = (value, extend = false, maxNmbOfChars = 20) => {
  if (value == null || value === '') {
    return '-';
  }
  switch (typeof value) {
    case 'boolean':
      return formatBoolean(value);
    case 'string':
      return formatString(value, extend, maxNmbOfChars);
    case 'object':
      return '-';
    case 'number':
      return value;
    default:
      return value;
  }
};

// const formatObject = (value) => {

//   if (value.ratedAlbums !== undefined || value.nominations !== undefined) {
//     return <div className="flex justify-around">
//       <p>Rated Albums</p>
//       <p>Rated Tracks</p>
//       <p>Rated Artist</p>
//     </div>
//   }
//   else return <div>{value}</div>;
// };

const formatBoolean = (value) => {
  if (value) {
    return <IoCheckmark className='text-blue-600' />;
  }
  return <IoClose className='text-red-600' />;
};

const formatString = (value, extend, maxNmbOfChars) => {
  if (value.includes('/media/')) {
    return <img alt='' src={value} className='h-20' />;
  }

  if (value.startsWith('http://') || value.startsWith('https://')) {
    return (
      <a href={value} className='text-blue-600 underline' target='_blank' rel='noreferrer'>
        link
      </a>
    );
  }
  let visibleValue = value;
  if (value.length >= maxNmbOfChars && !extend) {
    visibleValue = value.substring(0, maxNmbOfChars) + '...';
  }
  if (emailRegex.test(value.toLowerCase())) {
    return (
      <a href={'mailto:' + value} className='text-blue-600 underline'>
        {visibleValue}
      </a>
    );
  }
  if (value.match(isoDateRegex)) {
    const date = new Date(value);
    return date.toLocaleString();
  }
  return visibleValue;
};

export default formatField;
