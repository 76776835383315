import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {authService} from '../api/auth.service';
import FormResetPassword from '../forms/FormResetPassword';

const PasswordReset = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const {resetPasswordToken} = useParams();
  useEffect(() => {
    setError('');
    authService.checkResetPasswordValidity(resetPasswordToken).catch(() => setError('Your token is expired.'));

    return () => {
      setError('');
    };
  }, []);

  return (
    <>
      <div className='h-auto min-h-screen bg-main m-0 w-full flex items-center justify-center flex-col'>
        <div className='text-white font-sans justify-content'>PASSWORT WIEDERHERSTELLEN</div>

        <div className='p-5 h-full flex flex-col justify-center items-center'>
          <div className='w-full flex flex-col'>
            <p className='text-white text-center text-sm'>Bitte gib deine E-Mail ein</p>
            <div className='flex justify-center'>
              <div className='p-4 flex justify-center items-center'>
                {!error && (
                  <FormResetPassword
                    onSubmit={(data) =>
                      authService
                        .resetPasswordToken(data, resetPasswordToken)
                        .then(() => alert('Das Passwort wurde geändert'))
                        .then(() => {
                          navigate('/');
                        })
                        .catch(() => {
                          alert('Fehler aufgetreten');
                        })
                    }
                  />
                )}
                {error && <div className='w-full text-red-500 text-center text-2xl font-semibold p-4'>{error}</div>}
              </div>
            </div>
          </div>
          {error && <div className='text-xl text-red-500 text-center my-10'>{error}</div>}
          {success && (
            <div className='text-green-300 text-md text-center my-10'>
              Eine E-Mail mit einem Link zum Wiederherstellen Ihres Passworts wurde gesendet
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
