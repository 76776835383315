import React, {useEffect, useState} from 'react';

const AGB = () => {
  let[htmlFileString, setHtmlFileString] = useState();
  async function fetchHtml() {
    setHtmlFileString(await (await fetch('policies.html')).text());
  }
  useEffect(() => {
    fetchHtml();
  }, []);
  return (
    <>
      <div className='text-base text-white font-medium text-justify mt-8 mb-5 px-5'>
        {/* <a style={{ color: "#119783" }} href="/kaufoptionen">🢀 Zurück</a>
        <br/> */}
        <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
      </div>
    </>
  );
};

export default AGB;