import React, {useEffect, useState} from 'react';
import {genresService} from '../api/genres.service';
import {languagesService} from '../api/languages.service';
import BForm from '../components/forms/Form';

export default function UserForm({onSubmit, btnText, preloadedData, success, responseErrors}) {
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);

  const genders = [
    {
      label: 'Frau',
      value: 0,
    },
    {
      label: 'Herr',
      value: 1,
    },
  ];

  useEffect(() => {
    genresService.getAllGenres().then((res) => {
      let data = res.data.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setGenres(data);
    });
    languagesService.getAllLanguages().then((res) => {
      let data = res.data.map((el) => ({
        value: el.id,
        label: el.name,
      }));
      setLanguages(data);
    });
  }, []);

  const fields = [
    {
      fieldName: 'name',
      placeholder: 'Name',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'sex',
      placeholder: 'Anrede',
      type: 'select',
      options: [...genders],
      validation: {
        required: true,
      },
    },

    {
      fieldName: 'genresName',
      placeholder: 'Genres',
      type: 'select',
      isMulti: true,
      defaultValue: preloadedData ? preloadedData.genresName : null,
      options: [...genres],
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'languagesName',
      placeholder: 'Sprachen',
      type: 'select',
      isMulti: true,
      defaultValue: preloadedData ? preloadedData.languagesName : null,
      options: [...languages],
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'address',
      placeholder: 'Anschrift',
    },
    {
      fieldName: 'avatar',
      placeholder: 'Profilbild',
      type: 'file',
    },

    {
      fieldName: 'phone',
      placeholder: 'Telefonnummer',
    },
    {
      fieldName: 'description',
      placeholder: 'Bezeichnung',
    },
    {
      fieldName: 'company',
      placeholder: 'Markenname',
      validation: {
        required: true,
      },
    },
    {
      fieldName: 'website',
      placeholder: 'Webseite',
      validation: {
        required: true,
      },
    },
  ];

  return (
    <BForm
      success={success}
      responseErrors={responseErrors}
      preloadedData={preloadedData}
      btnText={btnText}
      submit={onSubmit}
      fields={fields}
      fieldColor='text-green-accent'
    />
  );
}
