import {useEffect, useState} from 'react';
import {Route, Routes, BrowserRouter, useNavigate} from 'react-router-dom';
import {authService} from '../api/auth.service';
import PasswordReset from '../pages/PasswordResetPage';
import PageRecoverPassword from '../pages/RecoverPasswordPage';
import PublicRoutes from './publicRoutes';
import PrivateRoutes from './privateRoutes';

export default function Router() {
  const [tokenState, setTokenState] = useState(authService.tokenValue);

  useEffect(() => {
    authService.setAccessTokenFromLocalStorage();
    authService.currentToken.subscribe(setTokenState);
  }, []);

  const isLoggedIn = () => {
    return tokenState !== '';
  };

  return (
    <BrowserRouter>
      {!isLoggedIn() && (
        <Routes>
          <Route path='/*' element={<PublicRoutes />} />
          <Route path='/forgotten-password' element={<PageRecoverPassword />} />
          <Route path='/reset-password/:resetPasswordToken' element={<PasswordReset />} />
        </Routes>
      )}
      {isLoggedIn() && (
        <Routes>
          <Route path='/*' element={<PrivateRoutes />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
