import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {authService} from '../api/auth.service';
import LoginForm from '../forms/account-login.form';
import BLoadingIndicator from '../components/indicators/LoadingIndicator';
import RegisterForm from '../forms/account-register.form';
import logo from '../assets/logo.png';
import {translateRegisterError} from '../utils/translateRegisterError';

export default function PublicRoutes() {
  const [error, setError] = useState('');
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(true);

  const navigation = useNavigate();

  const tryLogin = (formData) => {
    setLoading(true);
    authService
      .login(formData)
      .then(() => {
        navigation('/kontakte');
      })
      .catch((error) => {
        if (error.response.data.detail === 'This Partner account has not been approved!') {
          setError('Dieses Partnerkonto wurde noch nicht genehmigt!');
          return;
        }
        setError('Login oder Passwort falsch');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tryRegister = (formData) => {
    if(formData.agree === 0)
      return;

    if (formData.sex === 1) {
      formData.sex = 'female';
    }
    if (formData.sex === 0) {
      formData.sex = 'male';
    }

    if (formData.password !== formData.passwordConfirm) {
      setError('Die Passwörter stimmen nicht überein');
      return;
    }
    delete formData.passwordConfirm;
    setLoading(true);
    authService
      .register(formData)
      .then((res) => {
        setStatus(res.status);
        setError('');
      })
      .catch((error) => {
        setError(translateRegisterError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <BLoadingIndicator />;
  }

  return (
    <div className='h-auto min-h-screen bg-main m-0 w-full flex items-center justify-center flex-col'>
      <div
        className='bg-transparent w-1/3 rounded-2xl shadow-xl p-3
         		flex items-center justify-center flex-col login-form relative'
      >
        {isLoginForm ? (
          <LoginForm onSubmit={tryLogin} />
        ) : (
          <div>
            <div className='text-center bottom-3 left-0'>
              <img alt='' src={logo} />
              <p className='ml-0 text-white'>Kooperationspartner</p>
            </div>

          <div className='normal-case'>
            <RegisterForm onSubmit={tryRegister} />
          </div>

          </div>
        )}
        <span className='h-2 text-center text-red-500 text-sm absolute bottom-1'>{error}</span>
      </div>
      {status === 201 ? (
        <div className='text-green-accent text-center w-full'>
          Registrierung erfolgreich, folgen Sie dem Aktivierungslink in der E-Mail, die Sie erhalten haben.
        </div>
      ) : (
        ''
      )}
      <button
        className='my-2 text-white underline'
        onClick={() => {
          setError('');
          setStatus('');
          setIsLoginForm(!isLoginForm);
        }}
      >
        {isLoginForm ? 'Registrierung' : 'Anmeldung'}
      </button>
      <button className='my-2 text-white underline' onClick={() => navigation('/forgotten-password')}>
        Passwort vergessen
      </button>

      {isLoginForm ? (
        <div className='absolute -ml-10 text-center bottom-3 left-0'>
          <img alt='' src={logo} />
          <p className='-mt-8 ml-10 text-white'>Kooperationspartner</p>
        </div>
        ) : (
          <div/>
        )}
      
    </div>
  );
}
