import React, {useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {genresService} from '../../api/genres.service';
import {checkedGeneres} from '../../atoms/generes.atom';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';
import BErrorIndicator from '../../components/indicators/NoDataIndicator';
import {BNavigateBox} from '../../components/navigateBox/NavigateBox';

const BSettingsPage = () => {
  const checkedTypes = useRecoilValue(checkedGeneres);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [genres, setGenres] = useState();
  const [checked, setChecked] = useState(checkedTypes);

  const setNewGeneres = useSetRecoilState(checkedGeneres);
  setNewGeneres(checked);

  useEffect(() => {
    setIsLoading(true);
    setError(false);
    genresService
      .getAllGenres()
      .then((res) => {
        setGenres(res.data);
      })
      .catch((err) => {
        if (!err.response || err.response.status === 404) {
        }
        setError(true);
      })
      .finally((_) => {
        setIsLoading(false);
      });
  }, [checked]);

  const handleClick = (e) => {
    if (e.target.checked === true) {
      setChecked((prevState) => [...prevState, e.target.value]);
    } else if (e.target.checked === false) {
      setChecked((prevState) => [...prevState].filter((types) => types !== e.target.value));
    }
  };

  if (isLoading) {
    return <BLoadingIndicator />;
  } else if (error) {
    return <BErrorIndicator />;
  }

  return (
    <>
      <BNavigateBox title='Einstellungen' />
      <div className='bg-white rounded shadow-md flex justify-start items-start border-gray-400 py-5 px-5 m-6'>
        <div className='h-full w-1/2 flex-col flex-wrap'>
          <div className='text-2xl font-bold my-5 ml-2'>Anfragen nur von folgendem Genre</div>
          <div className='flex-col flex flex-wrap'>
            {genres &&
              genres.map((genre) => {
                return (
                  <div
                    key={genre.id}
                    className='flex flex-col items-start h-12 w-1/2 space-y-2 ml-2 font-bold flex-wrap'
                  >
                    <div className='flex space-x-4'>
                      <input
                        type='checkbox'
                        onClick={handleClick}
                        className='w-8 h-6 text-green-400'
                        id={genre.id}
                        name={genre.name}
                        value={genre.name}
                        defaultChecked={checkedTypes.includes(genre.name)}
                      />
                      <label for={genre.name}> {genre.name}</label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='text-2xl font-bold my-5 ml-2'>1 Anfrage pro User pro</div>
          <div className='flex flex-col items-start  h-16 w-1/4 space-y-4 ml-2 font-bold flex-wrap'>
            <div className='flex space-x-4'>
              <input type='checkbox' className='w-6 h-6 text-green-400' name='Woche' />
              <label for='Woche'> Woche</label>
            </div>
            <div className='flex space-x-4'>
              <input type='checkbox' className='w-6 h-6 text-green-400' name='Woche' />
              <label for='Woche'> Monat</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BSettingsPage;
