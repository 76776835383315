import React from "react";
import formatField from "../../utils/formatFields";

export default function BTableCell({ cell, maxChars}) {
  return (
    <div
      {...cell.getCellProps()}
      className={`td border-b border-gray-200 m-0 relative p-2 h-full cursor-pointer`}
    >
      {formatField(cell.value, false, maxChars)}
    </div>
  );
}
