import httpClient from './http.client';

const getPayments = () => {
  return httpClient.get('/payments/');
};
const sendPayments = (data) => {
  return httpClient.post('/payments/', data);
};

const sendPaymentInitialization = (data) => {
  return httpClient.post('/payment-initialization/', data);
};

const getPaymentsResults = (id) => {
  return httpClient.get(`/payments/${id}/confirmation/`);
};

export const paymentsService = {
  getPayments,
  sendPayments,
  getPaymentsResults,
  sendPaymentInitialization,
};
