import React, {useEffect, useState} from 'react';
import LoadingIndicator from '../../components/indicators/LoadingIndicator';
import MessageListItem from './MessageListElement';
import {messagesService} from '../../api/messages.service';
import {WhiteFrame} from '../../components/WhiteFrame';
import {partnerService} from '../../api/partners.service';

const MessagePage = () => {
  const [messagesList, setMessagesList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const messagesRes = await messagesService.getAllMessages();
        const messagesData = messagesRes.data;

        const unlockedProfilesRes = await partnerService.getUnlocked();
        const unlockedProfilesData = unlockedProfilesRes.data.map(
          (unlockedProfile) => unlockedProfile.id
        );

        const addsUnlocked = messagesData.map((message) => ({
          ...message,
          unlocked:
            unlockedProfilesData.includes(message.id) ||
            unlockedProfilesData.includes(message.id_receiver),
        }));

        const sortedList = addsUnlocked.sort((a, b) => (a.unlocked ? -1 : 1));

        setMessagesList(sortedList);
      } catch (error) {
        throw error.message;
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='py-3 pl-3 absolute mt-1 ml-10 bg-green-accent  rounded-md text-white w-56 cursor-pointer'>
        Nachrichten
      </div>

      <div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <WhiteFrame content={<MessageListItem data={messagesList} />} />
        )}
      </div>
    </>
  );
};

export default MessagePage;
