import {IonIcon} from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {useRecoilValue} from 'recoil';
import {lockClosedOutline, sendOutline, attachOutline} from 'ionicons/icons';
import {usersService} from '../../api/users.service';
import {messagesService} from '../../api/messages.service';
import {receiverMailAtom} from '../../atoms/users.atom';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';
import MessagesElements from './MessagesElements';
import {useUserNavigate} from '../../components/hooks/useUserNavigate';

const MessageChat = () => {
  let {id} = useParams();

  const handleNavigateUser = useUserNavigate();

  const messagesEndRef = useRef(null);
  const receiverMail = useRecoilValue(receiverMailAtom);

  const [currentUser, setCurrentUser] = useState();
  const [receiverId, setReceiverId] = useState();
  const [messagesDetail, setMessagesDetail] = useState();
  const [text, setText] = useState();
  const [choosenImage, setChoosenImage] = useState(null);
  const [userBlocked, setUserBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        messagesEndRef.current?.scrollIntoView({behavior: 'auto'});

        const messageRes = await messagesService.getMessagesDetail(id);
        setMessagesDetail(messageRes.data);

        const userRes = await usersService.getCurrentUser();
        setCurrentUser(userRes.data);

        const receiverRes = await usersService.getOtherUserProfile(id);
        setReceiverId(receiverRes.data.id);

        setUserBlocked(messageRes.data.is_blocked);
      } catch (error) {
        throw error.message;
      } finally {
        setIsLoading(false);

        const timer = setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
        }, 500);

        return () => clearTimeout(timer);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='py-3 pl-3 absolute top-2 left-14 bg-green-accent rounded-md text-white w-56 cursor-pointer'>
        Chat
      </div>
      {isLoading ? (
        <BLoadingIndicator />
      ) : (
        <div className='bg-white mx-10 my-6 rounded-lg'>
          <div className='flex flex-col w-full h-[88vh] '>
            <div className='flex justify-center text-lg text-green-accent font-bold border-b border-gray-200 py-3'>
              {receiverMail}
            </div>
            <MessagesElements
              endRef={messagesEndRef}
              data={messagesDetail?.messages}
              currentUserEmail={currentUser?.email}
            />
          </div>
          <div className='w-full p-2 border-t-2 rounded-b-xl flex justify-around'>
            <div className='w-[90%] flex text-black-accent'>
              <input
                value={text}
                onChange={(e) => setText(e.target.value)}
                className='text-black-accent text-sm bg-[#F2F2F2] rounded-md w-full h-8 px-2'
                placeholder={` ${userBlocked ? 'Der Benutzer hat den Chat blockiert' : 'Text...'}`}
              />
            </div>
            <div>
              <label htmlFor='single'>
                {!userBlocked && <IonIcon className='text-3xl' icon={attachOutline} />}
              </label>
              <input
                id='single'
                type='file'
                accept='.png, .jpg, .jpeg'
                className='hidden'
                onChange={(e) => {
                  const url = e.currentTarget.files;
                  setChoosenImage(url[0]);
                }}
              />
            </div>
            <IonIcon
              icon={userBlocked ? lockClosedOutline : sendOutline}
              className='text-2xl bg-black mt-1'
              onClick={async () => {
                if (choosenImage !== null) {
                  const message = {
                    content: text !== '' ? text : 'Anhang',
                    receiver: receiverId,
                    image: choosenImage,
                  };
                  await messagesService.sendMessage(message);
                } else {
                  const message = {
                    content: text,
                    receiver: receiverId,
                  };
                  await messagesService.sendMessage(message);
                }
                setText('');
                setChoosenImage('');

                const res = await messagesService.getMessagesDetail(+id);
                setMessagesDetail(res.data);
                messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MessageChat;
