import {IonIcon} from '@ionic/react';
import {pause, play} from 'ionicons/icons';
import React, {useEffect, useRef, useState} from 'react';
import {addLeadingZeros} from '../../utils/addLeadingZeros';
import BLoadingIndicator from '../../components/indicators/LoadingIndicator';

const AppAudioPlayer = ({tracks, firstTrackIndex = 0, isDefaultShuffle, tak}) => {
  const [trackIndex, setTrackIndex] = useState(firstTrackIndex);
  const [duration, setDuration] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRepeat] = useState(false);
  const [isShuffle] = useState(isDefaultShuffle);
  const [loading, setLoading] = useState(false);

  const {file} = tracks[trackIndex];

  const audioRef = useRef(new Audio(file));
  const intervalRef = useRef();
  const isReady = useRef(false);

  audioRef.current.onloadedmetadata = function () {
    isNaN(audioRef.current.duration) ? setLoading(false) : setLoading(true);
  };

  setTimeout(function () {
    audioRef.current.duration && setDuration(audioRef.current.duration);
  }, 200);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
    // eslint-disable-next-line
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(file);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(false);
      startTimer();
    } else {
      isReady.current = true;
    } // eslint-disable-next-line
  }, [trackIndex]);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const startTimer = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 1000);
  };

  const toNextTrack = () => {
    if (isRepeat) {
      seekTo(0);
    } else if (isShuffle) {
      const randomIndex = Math.floor(Math.random() * tracks.length);
      setTrackIndex(randomIndex);
      return;
    } else if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  // const onScrub = (value) => {
  //   if (!isDragging) return;

  //   clearInterval(intervalRef.current);
  //   audioRef.current.currentTime = value;
  //   setTrackProgress(audioRef.current.currentTime);
  // };

  // const onScrubEnd = () => {
  //   if (!isPlaying) {
  //     setIsPlaying(true);
  //   }

  //   setIsDragging(false);
  //   startTimer();
  // };

  const seekTo = (timeInSeconds) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = timeInSeconds;
    setTrackProgress(audioRef.current.currentTime);
    startTimer();
    setIsPlaying(false);
    setIsPlaying(true);
  };

  const getMinutesAndSeconds = (value) => {
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = Math.floor((value % 3600) % 60);
    return `${minutes}:${addLeadingZeros(seconds, 2)}`;
  };

  if (loading === false) {
    return (
      <div className='w-80 flex justify-center'>
        <div className='flex flex-col'>
          <div className='flex justify-center'>
            <div>{getMinutesAndSeconds(trackProgress)}</div>
            <div>{'-' + getMinutesAndSeconds(duration)}</div>
          </div>
          <div className='ml-5 text-3xl py-2'>
            <IonIcon
              onClick={() => setIsPlaying((prevState) => !prevState)}
              icon={isPlaying ? pause : play}
              className='text-green-accent'
            />
          </div>
        </div>
      </div>
    );
  } else return <BLoadingIndicator />;
};
export default AppAudioPlayer;
